<template>
  <div id="loading_box" ref="loadingIt">
    <div id="box">
      <div id="loading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div id="mask"></div>
  </div>
</template>

<script>
export default {
  name: "LoadingData",
  computed: {
    // 是否正在加载动画
    isLoading() {
      return this.$store.state.isLoading;
    }
  },
  watch: {
    // 是否正在加载动画
    isLoading(isLoad) {
      this.$refs.loadingIt.style.display = isLoad?"block":"none";
    }
  }
}
</script>

<style scoped>
  #loading_box {
    display: none;
  }
  #mask {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: #000;
    opacity: 0.7;
    filter: alpha(opacity: 70);
    z-index: 888;
  }
  #box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50% );
    z-index: 900;
  }

  #loading,
  #loading > div {
    position: relative;
    box-sizing: border-box;
  }
  
  #loading {
    display: block;
    font-size: 0;
    color: #fff;
  }
  
  #loading.la-dark {
    color: #333;
  }
  
  #loading > div {
    display: inline-block;
    float: none;
    background-color: currentColor;
    border: 0 solid currentColor;
  }
  
  #loading {
    width: 8px;
    height: 8px;
  }
  
  #loading > div {
    position: absolute;
    width: 19px;
    height: 19px;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    animation: ball-fussion-ball1 1s 0s ease infinite;
  }
  
  #loading > div:nth-child(1) {
    top: 0;
    left: 50%;
    z-index: 1;
  }
  
  #loading > div:nth-child(2) {
    top: 50%;
    left: 100%;
    z-index: 2;
    animation-name: ball-fussion-ball2;
  }
  
  #loading > div:nth-child(3) {
    top: 100%;
    left: 50%;
    z-index: 1;
    animation-name: ball-fussion-ball3;
  }
  
  #loading > div:nth-child(4) {
    top: 50%;
    left: 0;
    z-index: 2;
    animation-name: ball-fussion-ball4;
  }
  
  #loading.la-sm {
    width: 4px;
    height: 4px;
  }
  
  #loading.la-sm > div {
    width: 6px;
    height: 6px;
  }
  
  #loading.la-2x {
    width: 16px;
    height: 16px;
  }
  
  #loading.la-2x > div {
    width: 24px;
    height: 24px;
  }
  
  #loading.la-3x {
    width: 24px;
    height: 24px;
  }
  
  #loading.la-3x > div {
    width: 36px;
    height: 36px;
  }
  
  @keyframes ball-fussion-ball2 {
    0% {
      opacity: 0.35;
    }
  
    50% {
      top: 200%;
      left: 200%;
      opacity: 1;
    }
  
    100% {
      top: 100%;
      left: 50%;
      z-index: 1;
      opacity: 0.35;
    }
  }
  
  @keyframes ball-fussion-ball1 {
    0% {
      opacity: 0.35;
    }
  
    50% {
      top: -100%;
      left: 200%;
      opacity: 1;
    }
  
    100% {
      top: 50%;
      left: 100%;
      z-index: 2;
      opacity: 0.35;
    }
  }
  
  @keyframes ball-fussion-ball3 {
    0% {
      opacity: 0.35;
    }
  
    50% {
      top: 200%;
      left: -100%;
      opacity: 1;
    }
  
    100% {
      top: 50%;
      left: 0;
      z-index: 2;
      opacity: 0.35;
    }
  }
  
  @keyframes ball-fussion-ball4 {
    0% {
      opacity: 0.35;
    }
  
    50% {
      top: -100%;
      left: -100%;
      opacity: 1;
    }
  
    100% {
      top: 0;
      left: 50%;
      z-index: 1;
      opacity: 0.35;
    }
  }
</style>