<template>
    <div :class="['pointsItem', selected ? 'selected':'']">
        <div class="pointsNumber" v-if="maxCount < 99"><span v-if="limited === 'Weekly'">{{$t('center.Weekly')}}:</span><span v-if="limited === 'Monthly'">{{$t('center.Monthly')}}:</span> {{ currentCount }}/{{ maxCount }}</div>
        <div class="pointsImg">
            <img :src="icon" />
            <div class="pointsTitle">{{ title }}</div>
        </div>
        <div class="pointsText">
            <img :src="require('@/assets/imagine/topup/point.png')" style="width: 18px; margin-right: 2px;"/>
            <span>{{ point * count }}</span>
        </div>
        <div class="line"></div>
        <div class="pointsNum">
            <span class="pointsDelete" @click="deleteCount">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 -4 20 25" fill="none" v-if="count > 1"><circle cx="10" cy="10.9404" r="9.5" fill="white" stroke="#A0A0A0"></circle><rect x="4.67529" y="9.69043" width="10.6506" height="2.5" fill="#A0A0A0"></rect></svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 -4 20 25" fill="none" v-if="count <= 1"><circle cx="10" cy="10.9404" r="9.5" fill="white" stroke="#DBDBDB"></circle><rect x="4.67529" y="9.69043" width="10.6506" height="2.5" fill="#DBDBDB"></rect></svg>
            </span>
            <input type="number" name="numberInput" aria-label="numberInput" disabled class="pointsIpt" v-model="count" />
            <span class="pointsAdd" @click="addCount">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 -4 20 25" fill="none" v-if="count < maxCount - currentCount"><path d="M10 0.94043C4.48 0.94043 0 5.42043 0 10.9404C0 16.4604 4.48 20.9404 10 20.9404C15.52 20.9404 20 16.4604 20 10.9404C20 5.42043 15.52 0.94043 10 0.94043ZM15 11.9404H11V15.9404H9V11.9404H5V9.94043H9V5.94043H11V9.94043H15V11.9404Z" fill="#557be1"></path></svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 -4 20 25" fill="none" v-if="count >= maxCount - currentCount"><path d="M10 0.94043C4.48 0.94043 0 5.42043 0 10.9404C0 16.4604 4.48 20.9404 10 20.9404C15.52 20.9404 20 16.4604 20 10.9404C20 5.42043 15.52 0.94043 10 0.94043ZM15 11.9404H11V15.9404H9V11.9404H5V9.94043H9V5.94043H11V9.94043H15V11.9404Z" fill="#DBDBDB"></path></svg>
            </span>
        </div>
        <div :class="currentCount == maxCount ? 'shopBtn disabled' : 'shopBtn'" @click="redeemNow()"><div class="price">{{ $t('center.RedeemNow') }}</div></div>
    </div>
</template>

<script>

export default {
    name: "PointShopItem",
    props: {
        selected: {
            type: Boolean,
            default: false
        },
        itemSku: {
            type: String,
            Required: true
        },
        title: {
            type: String,
            Required: true
        },
        point: {
            type: Number,
            Required: true
        },
        icon: {
            type: String,
            Required: true
        },
        currentCount: {
            type: Number,
            Required: true
        },
        maxCount: {
            type: Number,
            Required: true
        },
        limited: {
            type: String,
            Required: true
        }
    },
    data(){
        return {
            count: 1
        }
    },
    watch: {
        currentCount(newVal, oldVal){
            if (this.maxCount <= this.currentCount){
                this.count = 0;
            } else {
                this.count = 1;
            }
        }
    },
    methods: {
        addCount() {
            if (this.maxCount <= this.currentCount){
                this.count = 0;
            } else {
                this.count += 1;
                if (this.count > this.maxCount - this.currentCount){
                    this.count = this.maxCount - this.currentCount;
                }
            }
        },
        deleteCount() {
            if (this.maxCount <= this.currentCount){
                this.count = 0;
            } else {
                this.count -= 1;
                if (this.count <= 0){
                    this.count = 1;
                }
            }
        },
        redeemNow(){
            if (this.maxCount - this.currentCount > 0){
                this.$emit('redeem', this.itemSku, this.count);
            } else {
                this.$store.commit("changeAlertMsg", this.$t("center.reachedLimit"));
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.pointsItem {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: center;
    padding: 20px 24px;
    border-radius: 8px;
    border: 1px solid #eee;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    .shopBtn {
        width: 100%;
        padding: 8px 12px;
        background-color: #557be1;
        border-radius: 6px;
        text-align: center;
        cursor: pointer;
        .price {
            text-overflow: ellipsis;
            color: #fff;
            font-size: 14px;
            font-weight: 600;
            line-height: 28px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            word-break: break-all;
        };
        &.disabled {
            background-color: rgb(175, 170, 170);
        }
    }
    .pointsImg {
        display: flex;
        align-items: start;
        justify-content: center;
        width: 100%;
        padding-top: 20px;
        margin-bottom: 10px;
        overflow: hidden;
        position: relative;
        height: 150px;
        img {
            width: 90px;
            height: auto;
        }
    }
    .pointsTitle {
        position: absolute;
        bottom: 0;
        width: 100%;
        color: #333;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        text-align: center;
    }
    .pointsText {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        span {
            color: #ff6d3c;
            font-size: 16px;
            line-height: 1.2;
            display: inline-flex;
            align-items: center;
            font-weight: 600;
        }
    }
    .line {
        width: 100%;
        height: 1px;
        background-color: #eee;
        margin: 16px 0;
    }
    .pointsNum {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
    }
    .pointsIpt {
        width: 68px;
        padding: 0 6px;
        text-align: center;
        background: #fff;
        border: 0 solid #ddd;
    }
    .pointsNumber {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        line-height: 13px;
        padding: 3.5px 12px;
        border-radius: 0 0 0 8px;
        background: #ff6d3c;
    }
}

.pointsItem:hover {
    box-shadow: 0 4px 20px 0 rgba(0,0,0,.1);
    background: #fff
}

.selected, .selected:hover {
    padding: 19px 23px;
    border: 2px solid #557be1;
}

@media screen and (max-width: 767px) {
    .pointsItem {
        padding: 10px 8px;
    }
    .selected, .selected:hover {
        padding: 9px 7px;
    }
}

</style>