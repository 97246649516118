import { requestUrl } from "@/request/index";
const basicJson = require('../config');
const baseURL = basicJson.apiUrl;

// 获取订单所需数据
export function redeem(appid, userId, itemSku, count, country, sandbox) {
    return requestUrl(`${baseURL}/point_mall`, {
        method: "POST",
        data: {
            appid,
            userId,     // 用户 ID
            pointId: itemSku,    // 商品 sku
            amount: count,
            country,
            sandbox
        }
    });
}

export function getItemLimited(appid, userId, country, sandbox) {
    return requestUrl(`${baseURL}/point_mall_user`, {
        method: "POST",
        data: {
            appid,
            userId,     // 用户 ID
            country,
            sandbox
        }
    });
}