import { createApp } from 'vue'
import App from './App.vue'
import Vuex from "./store/index";
import router from './router/index';
import { i18n } from "./language/index";

import PointShop from './components/PointShop';
import MainContent from './views/topup/child/MainContent.vue';
import CheckInList from './views/topup/CheckInList.vue';

createApp(App)
    .use(Vuex)
    .use(router)
    .use(i18n)
    .component('point-shop', PointShop)
    .component('main-content', MainContent)
    .component('checkin-list', CheckInList)
    .mount('#app');