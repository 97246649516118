<template>
  <div id="main_content">
    <div id="main_description">
      <p id="main_title"><span>{{agreementPageData.title}}</span></p>
      <div class="description_list"
           v-for="(despItem, index) in agreementPageData['content']"
           :key="index">
        <p :class="[despItem.isTitle?'despClassTitle':'despClassItem']">
          <span>{{despItem.content}}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainContent",
  data() {
    return {
      agreementPageData: {
        title: 'Terms of Service',
        content: [
          {
            isTitle: false,
            content: "Thanks for using the Service which is provided by IVYMOBILE INTERNATIONAL ENTERPRISE LIMITED, located at Room1318-19, 13/F, Hollywood Plaza, 610 Nathan Road,\n" +
                "Mongkok, Kowloon, Hong Kong. BY INSTALLING OR OTHERWISE ACCESSING OR USING THE SERVICE, YOU AGREE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THIS Terms of Service. IF YOU DO NOT AGREE TO Terms of Service, THEN YOU SHOULD NOT USE THE SERVICE. Merge Neverland, (We or the Company) may change these Terms of Service at any time without notice, effective upon its posting to the App. Your continued use of the App shall be considered your acceptance to the revised Terms of Service. If you do not agree to these Terms of Service, please do not use this App.",
          },
          {
            isTitle: true,
            content: "1. Notice"
          },
          {
            isTitle: false,
            content: "The App may include features that promote Modify the desktop layout. Merge Neverland is not responsible or liable for any injuries or damages you may sustain that result from your use of, or inability to use, the features of the App."
          },
          {
            isTitle: true,
            content: "2. LIMITED LICENSE"
          },
          {
            isTitle: false,
            content: "Subject to your complete and ongoing compliance with all the terms and conditions set forth in these Terms (including without limitation payment of any applicable fees and compliance with all license restrictions), Merge Neverland grants you permission to access and use the application, only for your personal use. You may not reproduce, distribute, publicly display, or publicly perform any part of the application. You may not use the application for any purpose other than a purpose for which the application is expressly designed. If you are prohibited under applicable law from using the application, you may not use it."
          },
          {
            isTitle: true,
            content: "3. Intellectual Property"
          },
          {
            isTitle: false,
            content: "All intellectual property on the App is owned by Merge Neverland or its licensors, which includes materials protected by copyright, trademark, or patent laws. All trademarks, service marks and trade names are owned, registered and/or licensed by Merge Neverland. All content on the App (except for Personal Information), including but not limited to text, software, scripts, code, designs, graphics, photos, sounds, music, videos, applications, interactive features and all other content (“Content”) is a collective work under the United States and other copyright laws and is the proprietary property of Merge Neverland ; All rights reserved."
          },
          {
            isTitle: true,
            content: "4. Links"
          },
          {
            isTitle: false,
            content: "The App may contain links to websites, applications or other products or services operated by other companies (“Third Party Platforms”). Merge Neverland does not endorse, monitor or have any control over these Third Party Platforms, which have separate Terms of Service and privacy policies. Merge Neverland is not responsible for the content or policies of Third Party Platforms and you access such Third Party Platforms at your own risk."
          },
          {
            isTitle: true,
            content: "5. Personal Information"
          },
          {
            isTitle: false,
            content: "To use the App, you may be asked to provide certain personal information (the “Personal Information”), including information about your 【weight, age and gender】, to optimize your use of the App. If you choose to provide such Personal Information, you agree to provide accurate and current information about yourself, and Merge Neverland will not be responsible for any injury related to any Personal Information you submit to the App or omit. The use or disclosure of any Personal Information you supply will be governed by our Privacy Policy."
          },
          {
            isTitle: true,
            content: "Ⅰ、What Information We May Collect",
          },
          {
            isTitle: false,
            content: "A. Information your directly and voluntarily provide to us"
          },
          {
            isTitle: false,
            content: "We may collect information that you provide to us. The information includes but is not limited to: your email address, the content of feedback, and the content of questionnaire answered by you."
          },
          {
            isTitle: false,
            content: "B. Information automatically collected when you use our Service",
          },
          {
            isTitle: false,
            content: "We will collect information including, but not limited to: your mobile device’s brand, model, operating system, resolution, screen size, system version, Bluetooth settings, internet connection, RAM, history, the apps you have installed, the apps you have running in the background, mobile device’s Android Advertising ID, along with your account activation time, content displayed to you and whether you clicked on it, advertisements displayed to you and whether you clicked on them, URLs you have visited, notifications that have appeared on your mobile device, your IP address, your mobile country code, and your identity. We will also request access to your photos, media and files, your device’s camera and microphone, your Wi-Fi connection information and your device ID and call information."
          },
          {
            isTitle: false,
            content: "C. Information collected by our Third Party Advertising Partners."
          },
          {
            isTitle: false,
            content: "We work with third party advertising partners to provide personalized ads for you.\n Our partners may collect hardware and software information such as IP address, device ID and type, advertising IDs (such as Google's AAID and Apple's IDFA, both of which are randomly generated numbers that you can reset by going into your device' settings), device language, operating system, time zones, and identifiers associated with cookies or other technologies that may uniquely identify your device."
          },
          {
            isTitle: false,
            content: "D. Information collected by Third Parties Analytics Services",
          },
          {
            isTitle: false,
            content: "We may work with third party analytics services to help us provide you with better service. Information might be analyzed such as how often you use the application and the events that occur within the application. We collect this type of analysis information and use it in conjunction with the analytical information collected from other users so that such information can not be used to identify any specific individual user.\nIf you give us personal information of somebody else, we will assume that you have their permission to do so. Transmissions to and from Merge Neverland or the Site may not be confidential and consequently, may be read or intercepted by others."
          },
          {
            isTitle: true,
            content: "Ⅱ、How We May Use Collected Information"
          },
          {
            isTitle: false,
            content: "A. To improve customer service\nInformation you provide helps us respond to your customer service requests and support needs more efficiently.\nB. To personalize user experience\nWe may use information in the aggregate to understand how our Users as a group use the services and resources provided on our Site.\nC. To improve our Site\nWe may use feedback you provide to improve our products and services.\nE. To process payments\nWe may use the information Users provide about themselves when placing an order only to provide service to that order. We do not share this information with outside parties except to the extent necessary to provide the service.\nF. To run a promotion, contest, survey or other Site feature\nTo send Users information they agreed to receive about topics we think will be of interest to them.\nG. To send periodic emails\nWe may use the email address to send User information and updates pertaining to their order. It may also be used to respond to their inquiries, questions, usage tips, website news and/or other requests, promotions. If at any time the User would like to unsubscribe from receiving future emails, they must delete their account in settings page under profile."
          },
          {
            isTitle: true,
            content: "Ⅲ、Disclosure of Your Personal Information"
          },
          {
            isTitle: false,
            content: "We may disclose your personal information to our business partners, including advertisers, publishers, and data tracking companies. These business partners may use your personal information to show you advertisements, measure your ad/site interaction, and better understand the site and app traffic usage or user behaviour in order to improve their services. Such partners’ use of the information that we disclose to them will be governed by their privacy policies.\nWe may disclose your personal information to our trusted agents and service providers so that they can perform requested services on our behalf. For example, we may share payment information with a third party service provider in order to assist us with processing credit card payments. These entities are prohibited from using User’s information for any purposes other than providing us with requested services. We may also share aggregate or anonymous information with other third parties in accordance with the terms of this Policy.\nOur affiliates may also have access to your personal information collected through Merge Neverland for the purposes set out in this Privacy Policy.\nWe may share your personal information as required or permitted by law to comply with a subpoena or similar legal process or government request, or when we believe in good faith that disclosure is legally required or otherwise necessary to protect our rights and property, or the rights, property or safety of others, including to advisers, law enforcement agencies, judicial and regulatory authorities. We may also transfer your personal information to a third party that acquires all or part of our assets or shares, or that succeeds us in carrying on all or a part of our business, whether by merger, acquisition, reorganisation or otherwise."
          },
          {
            isTitle: true,
            content: "Ⅳ、 Security of Your Personal Information"
          },
          {
            isTitle: false,
            content: "The security of your information is our utmost priority, and we take strict measures in order to protect our data from unauthorized access, use, disclosure or destruction of data. We have implemented physical, technical and administrative security measures for Merge Neverland Services that comply with applicable laws and industry standards. For example, we use firewalls, encryption technology and other automated software designed to protect against fraud and identity theft; our data is only stored in centers that provide high-level security for your information. We strictly prohibit any user unreasonably using the privacy of others obtained during the use of the software."
          },
          {
            isTitle: true,
            content: "6. Mobile Services"
          },
          {
            isTitle: false,
            content: "The App contains services and features that are available to certain mobile Devices. Your carrier’s normal rates and fees apply. Not all mobile services will work with all carriers or Devices. By using Merge Neverland’s mobile services, you agree that we may communicate with you by electronic means to your mobile Device and that certain information about your use of these services may be shared with us. If you change or deactivate your mobile phone number, you must promptly update your account information to ensure that we don’t send your messages to a different person."
          },
          {
            isTitle: true,
            content: "7. Indemnification",
          },
          {
            isTitle: false,
            content: "You agree to indemnify, defend, and hold harmless Merge Neverland APPS, its affiliates, officers, directors, employees, agents, licensors and suppliers from and against all claims, losses, liabilities, expenses, damages and costs, including, without limitation, attorneys’ fees, arising from or relating in any way to your Personal Information, your use of the App, your conduct in connection with the App or with other App users, or any violation of these Terms of Service, any law or the rights of any third party."
          },
          {
            isTitle: true,
            content: "8. Privacy"
          },
          {
            isTitle: false,
            content: "Our Privacy Policy, which is incorporated into these Terms of Service by this reference, further describes the collection and use of information on this App."
          },
          {
            isTitle: true,
            content: "9. User Behavior Norms"
          },
          {
            isTitle: false,
            content: "9.1 The content referred to in this Agreement refers to any content that is transmitted, uploaded, and shared by users during the use of Merge Neverland , including but not limited to registration information such as account avatar, name, user description, and authentication materials.\n" +
                
                "9.2 Users may not use the Merge Neverland account or the Service to disseminate content prohibited by the following laws, regulations and policies:\n" +
                
                "1) against the basic principles established by the Constitution;\n" +
                
                "2) endangering national security, leaking state secrets, subverting state power, and undermining national unity;\n" +
                
                "3) harming national honors and interests;\n" +
                
                "4) Inciting ethnic hatred, ethnic discrimination, and undermining national unity;\n" +
                
                "5) Destroy the national religious policy and promote cults and feudal superstitions;\n" +
                
                "6) Spreading rumors, disrupting social order and undermining social stability;\n" +
                
                "7) spreading obscenity, pornography, gambling, violence, murder, terror or abetment;\n" +
                
                "8) Insulting or slandering others and infringing upon the lawful rights and interests of others;\n" +
                
                "9) infringe on the intellectual property rights, copyright or public private rights of any third party;\n" +
                
                "10) Violation of human morality and customs (e.g., maliciously screening and insulting others within the platform);\n" +
                
                "11) Any form of advertising that is not permitted in writing by the company;\n" +
                
                "12) Information containing other content prohibited by law or administrative regulations.\n" +
                
                "9.3 Users may not use the Merge Neverland account or the service to disseminate the following content that interferes with Merge Neverland normal operations or infringes the legitimate rights and interests of other users or third parties:\n" +
                
                "1) contains any sexual or suggestive;\n" +
                
                "2) Containing insults, intimidation, threats;\n" +
                
                "3) Contains harassment, junk advertisements, malicious information, and scam information;\n" +
                
                "4) Involving the privacy, personal information or information of others;\n" +
                
                "5) Infringement of other people's right to reputation, portrait rights, intellectual property rights, trade secrets and other legal rights and interests;\n" +
                
                "6) Contains other information that interferes with the normal operation of the Service and infringes the content of other users or third party legal rights.\n" +
                
                "9.4 Without the written permission of the company, you may not, by yourself, authorize, permit or assist any third party to perform the following acts on the information in the Merge Neverland and related services of this Agreement:\n" +
                
                "1) copy, read, and use the information content of Merge Neverland and related services for commercial purposes including but not limited to publicity, increased reading, and page views;\n" +
                
                "2) arbitrarily edit, organize, and arrange the information content of the Merge Neverland and related services, and display it in channels other than the source page of Merge Neverland and related services;\n" +
                
                "3) Adopt any form of identification including but not limited to special signs, special codes, etc., to assist the third party to generate traffic, read volume guidance, transfer, hijacking, etc. for the information content of the Merge Neverland and related services;\n" +
                
                "4) Other acts of illegally obtaining or using the information content of Merge Neverland and related services.\n" +
                
                "9.5 If the company discovers or receives reports from others or complains that the user violates the provisions of this agreement, the company has the right to review and delete the relevant content, including but not limited to the user information, at any time without notice, and to include the violation account according to the seriousness of the case. Not limited to warnings, account bans, device bans, functional bans, and notify users to process results.\n" +
                
                "9.6 Users who have been banned due to violation of this Agreement may contact the company by themselves. Among them, the user who is blocked by the function will automatically resume the banned function after the expiration of the ban period. The banned user can submit an appeal, and the company will review the appeal and make a reasonable judgment on whether to decide whether to change the penalty.\n" +
                
                "9.7 The User understands and agrees that the Company shall have the right to impose penalties on violations of relevant laws and regulations or this Agreement in accordance with reasonable judgment, take appropriate legal actions against any user who violates laws and regulations, and report relevant information to relevant departments in accordance with laws and regulations. The user shall bear all legal liabilities arising therefrom.\n" +
                
                "9.8 The User understands and agrees that any claims, claims or losses claimed by any third party arising out of or arising out of the User's breach of this Agreement, including reasonable attorneys' fees, shall be compensated by the user to the Company."
          },
          {
            isTitle: true,
            content: "10. Refund Policy"
          },
          {
            isTitle: false,
            content: "If you purchased an in-app product but didn’t receive it, you should contact us to request a refund. we will refund after receiving your refund request and verifing the authenticity of the unreceived in-app product.\n"
          },
          {
            isTitle: true,
            content: "11. User Interaction Disclaimer"
          },
          {
            isTitle: false,
            content: "You are solely responsible for your interactions with other people, whether online or in person. Merge Neverland is not responsible or liable for any loss or damage resulting from any interaction with other App users, persons you meet through the App, or persons who find you because of information posted on, by or through the App. You agree to take reasonable precautions in all interactions with other users on the App, and conduct any necessary investigation before meeting another person. Merge Neverland is under no obligation to become involved with any user dispute, but may do so at its own discretion."
          },
          {
            isTitle: true,
            content: "12. Warranty Disclaimer"
          },
          {
            isTitle: false,
            content: "Merge Neverland is not responsible or liable for any Personal Information posted on the App or for any offensive, unlawful or objectionable content you may encounter on or through the App. The App is provided “AS IS” and without warranties of any kind. To the fullest extent permitted by law, Merge Neverland disclaims all warranties, express or implied, including, but not limited to, implied warranties of title, merchantability, fitness for a particular purpose and non-infringement. Merge Neverland cannot guarantee and does not promise any specific results from use of the App. Merge Neverland does not represent or warrant that the App will be uninterrupted or error-free, that any defects will be corrected, or that this App or the server that makes the App available are free of viruses or anything else harmful. To the fullest extent permitted by law, Merge Neverland does not make any warranties or representations regarding the use of the materials or Content in the App in terms of their correctness, accuracy, adequacy, usefulness, reliability or otherwise. You understand and agree that you download or otherwise obtain Content at your own risk, and that you will be solely responsible for your use and any damage to your mobile Device, computer system or other Device in which you access the App, loss of data or other harm of any kind that may result. Merge Neverland reserves the right to change any and all Content and other items used or contained in the App at any time without notice. Some states do not permit limitations or exclusions on warranties, so the above limitations may not apply to you."
          },
          {
            isTitle: true,
            content: "13. Limitation of Liability"
          },
          {
            isTitle: false,
            content: "Merge Neverland SHALL NOT BE LIABLE FOR ANY DIRECT, SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES, INCLUDING FOR ANY LOST PROFITS OR LOST DATA, THAT RESULT FROM THE USE OF, OR THE INABILITY TO USE, THIS APP OR THE CONDUCT OF OTHER APP USERS (WHETHER ONLINE OR OFFLINE) OR ATTENDANCE AT A Merge Neverland EVENT OR ANY PERSONAL INFORMATION, EVEN IF Merge Neverland HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOU ASSUME TOTAL RESPONSIBILITY FOR YOUR USE OF THE APP. YOUR ONLY REMEDY AGAINST Merge Neverland FOR USE OF THE APP OR ANY CONTENT IS TO STOP USING THE APP. THAT SAID, IF Merge Neverland IS FOUND TO BE LIABLE TO YOU FOR ANY DAMAGE OR LOSS WHICH IS IN ANY WAY CONNECTED WITH YOUR USE OF THIS APP OR ANY CONTENT, Merge Neverland’S LIABILITY SHALL NOT EXCEED US$100.00. APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY OR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU."
          },
          {
            isTitle: true,
            content: "14. Miscellaneous"
          },
          {
            isTitle: false,
            content: "You agree that this App, Terms of Service, Privacy Policy and any dispute between you and Merge Neverland shall be governed in all respects by Hong Kong law, without regard to choice of law provisions, and not by the 1980 U.N. Convention on contracts for the international sale of goods. Except where prohibited, you agree that all disputes, claims and legal proceedings directly or indirectly arising out of or relating to this App (including but not limited to the purchase of Merge Neverland products) shall be resolved individually, without resort to any form of class action, and exclusively in the state or federal courts located in Hong Kong. You consent to waive all defenses of lack of personal jurisdiction and forum non conveniens with respect to venue and jurisdiction in the state and federal courts of Hong Kong. Any cause of action or claim you may have with respect to the App (including but not limited to the purchase of Merge Neverland products) must be commenced within one (1) year after the claim or cause of action arises. By using the App, you agree to receive certain electronic communications from Merge Neverland. You agree that any notice, agreement, disclosure or other communication that Merge Neverland sends you electronically will satisfy any legal communication requirements, including that such communications be in writing. Merge Neverland’s failure to insist upon or enforce strict performance of any of these Terms of Service shall not be considered a waiver of any provision or right. Neither the course of conduct between the parties nor trade practice shall modify any of these Terms of Service. Merge Neverland may assign its rights and duties under these Terms of Service to any party at any time without notice to you."
          },
          {
            isTitle: true,
            content: "15. Severability"
          },
          {
            isTitle: false,
            content: "If any provision in these Terms of Service is held invalid, the remainder of these Terms of Service shall continue to be enforceable. If any provision in these Terms of Service is deemed unlawful, void or unenforceable, then that provision is deemed severable from these Terms of Service and the remaining provisions are still valid and enforceable."
          },
          {
            isTitle: true,
            content: "16. Termination"
          },
          {
            isTitle: false,
            content: "You agree that Merge Neverland has the right to suspend or deactivate your account or your access to certain parts of Merge Neverland at any time and without notice or liability to you. Upon such termination we might delete your account and other information related to your account.\n" +
                
                "Reason for such termination could be violations of this agreement, requests from law enforcement or other government agency requests, but it is not limited to any of these."
          },
          {
            isTitle: true,
            content: "17. Contact Us"
          },
          {
            isTitle: false,
            content: "If you have any question or comment about this Terms of Service, please contact us by mergeneverland@gmail.com."
          }
        ]
      }
    }
  },
}
</script>

<style scoped lang="scss">
  #main_content {
    padding: 0 58px;
    margin-bottom: 66px;
    #main_description {

      #main_title {
        color: #000000;
        text-align: center;
        margin: 23px 0;
        font-size: 19px;
        span {
          font-family: Lato, sans-serif ;
        }
      }
    }
  }
  .despClassTitle {
    color: #000000;
    //font-family: Arial;
    font-weight: 700;
  }
  .despClassItem {
    color: #000000;
    //font-family: Arial;
    font-weight: 400;
    white-space: pre-wrap;
  }


  /* 适配移动端 */
  @media screen and (max-width: 800px){
    #main_content {
      padding: 0 25px;
    }
    //.main_title  {
    //  span {
    //    font-size: 50px;
    //  }
    //}
  }
</style>