export default {
    home: {
        langNow: "English",
        center: "TOP-UP Center",
        saveTo: "Add to Home Screen",
        agreement: "User Agreement",
        privacy: "Privacy Policy",
        QuickAccess: "Quick Access"
    },
    center: {
        account: "Top Up Account",
        userId: "User ID:",
        nickname: "Nickname:",
        enterId: "Enter User ID",
        logIn: "Log In",
        logOut: 'Log Out',
        exist: "Your user ID does not exist",
        check: "Please Check Your User ID",
        empty: "Your user ID cannot be empty",
        notLogin: "Not logged in, please log in and repurchase.",
        method: "Payment Method",
        amount: "Top Up Amount",
        SelectItem: "Select Item",
        bonus: "Bonus",
        purchase: "Purchase",
        safari: "Tips：Please open this page using Safari browser. If you are already using it, please ignore and proceed with the payment.",
        about: "About Top Up",
        about1: "1. The login account must match the account logged in within the game.",
        about2: "2. Important: After entering the account, please carefully check the displayed account information and confirm it before proceeding to the next step!",
        about3: "3. After successful recharge, the system will deliver to your recharge account. Please check within the game.",
        about4: "4. Limited-time offer: Starting from March 1st, using the official website recharge center will entitle you to an additional 10% bonus.",
        about5: "Customer service email:",
        about6: ".",
        pay: "Pay",
        price: "Price",
        total: "Total",
        save: "Save card information for future payments",
        agree: "I have read and agree to the Privacy Policy",
        proceed: "Proceed to payment",
        cancel: "Are you sure you want to cancel this payment?",
        cancelPay: "Cancel",
        continue: "Continue",
        success: "Payment successful! The purchased items have been sent to your recharge account. Please check within the game. For easier access and a better experience, please add to Home Screen.",
        failed1: "Payment failed, please try purchasing again.",
        failed2: "If it fails multiple times, click on the customer service entrance on the homepage or send an email to customer service.",
        shopping: "Continue payment",
        addTo: "Add to Home Screen",
        install: "Install app?",
        installNow: "Install Now",
        cancelInstall: "Cancel",
        bookmark: "Add a webpage to a bookmark",
        saveTitle: "Bookmark",
        savePage1: 'Click the "star icon" on the address bar.',
        savePage2: "or",
        savePage3: "Ctrl",
        savePage4: "save this page.",
        checkLimit: "The number of queries has reached the limit for today, please try again later.",
        reachedLimit: "The product has reached the maximum number of orders, please try again later.",
        unopened: "The application has temporarily not enabled the official website recharge function",
        createFailed: "Your order creation failed, please try again.",
        customerService: "Customer Service",
        FAQTitle: "FAQs",
        qtext1: "1.What is Mergeland-Alice's Adventure store?",
        qtext2: "2.How can I bind my account to purchase items? I have run into some problems during account binding.",
        qtext3: "3.How long will it take before items purchased in Mergeland-Alice's Adventure store appear in my game?",
        qtext41: "If you run into any problems related to order in Mergeland-Alice's Adventure store, please contact our official customer service by searching Mergeland-Alice's Adventure on Facebook. To facilitate our further investigation, please make sure that the following information about purchased items are provided: ",
        qtext42: "Number, time and order number of purchased items as well as your ID/UID.",
        qtext43: "Screenshots of purchase receipts or error information. Please do not show screenshots of receipts that have not been completed by payment providers.",
        atext1: "Mergeland-Alice's Adventure store is the official online market of Mergeland-Alice's Adventure.",
        atext21: "You have to login your game account before purchasing items in the store. First, please access the store with the default browser on your mobile devices installed with the game (Incognito mode is not supported).",
        atext22: "When you tap the 'Login' button or purchase items, the game will be launched and go back to the store page automatically. After login, you can see your user name on the store page. ",
        atext23: "If the game fails to go back to the store page automatically, it means your game may be of an old version; you need to relaunch the game; or old cookies may be saved in your browser's history. Please make sure that your game is the latest version. You can check for updates in Play Store or iOS App Store. You can also clear your browsing history on the History page of your browser.",
        atext24: "If your game is the latest version, please restart the App.",
        atext25: "iOS Devices: ",
        atext26: "If you want to exit the game, please first find the game by swiping right in App switcher, and then swipe up to exit.",
        atext27: "If you want to relaunch the App, please go to the homepage (or App store) to find it and tap it to start. ",
        atext28: "Android Devices: ",
        atext29: "Forcibly close the game and relaunch it.",
        atext3: "Items purchased in Mergeland-Alice's Adventure store will be sent to your in-game email immediately. If you fail to receive them, you could relaunch the game to check them again.",
        pendingPayment: "Pending payment",
        paymentSoon: "Please complete the payment as soon as possible. If already paid, please check in the game later.",
        paymentComplete: "Payment complete",
        payAgain: "Pay again",
        paymentSuccessfulOrderNotFound: "No successful order found",
        OrderHasBeenSuccessfullyPaid: "Order has been successfully paid",
        loginAgainToGift: "Not logged in, please log in again to receive",
        successfulClaim: "Successful claim, please check your email in the game later",
        freeGift: "Free Gift",
        receiveGift: "Receive Gift",
        alreadyReceived: "You have already received, please do not claim it again",
        receiveError: "Claim failed, please contact customer service.",
        noNumber: "Incorrect format, please enter a pure numerical ID",
        tos: "Privacy Policy",
        term: "I have read and agree to the {0}",
        AddToHomeScreen: "Add to Home Screen",
        AddToHomeText1: "1.Tap the button in the toobar below ",
        AddToHomeText2: '2.Swipe down and select "Add to Home Screen"',
        productHasExpired: "The product has expired, please purchase another one.",
        BelowTheMinimum: "Sorry, the item is below the minimum amount for the payment method, please choose another item to try again.",
        purchaseLimit: "Your current item is a limited purchase item. There is a pending order waiting for payment created at {limit}. It will expire in ",
        purchaseLimit3: " minutes. Please complete the payment as soon as possible. You can place a new order after it expires.",
        purchaseLimit2: "Your current item is a limited purchase item. There is a pending order waiting for paymen. It will expire in 30 minutes. Please complete the payment as soon as possible. You can place a new order after it expires.",
        ok: "OK",
        beforePurchasingAgain: "Are you sure you want to cancel this payment? Your current item is a limited purchase item, with only {0} more purchase opportunity left. If you cancel, you will need to wait 30 minutes before purchasing again.",
        payNumber: "1",
        UIDLogin: "User ID Login",
        AccountLogin: "Account Login",
        TOPUP: "TOP-UP",
        PointsShop: "Points Shop",
        PointsExchange: "Points Exchange",
        CurrentPoints: "Current Points",
        ConsumptionHistory: "Consumption History",
        logInToParticipate: "Not logged in, please log in to participate in points exchange",
        InsufficientPoints: "Insufficient points, unable to redeem for now",
        ExchangeSuccessful: "Exchange successful, please check your in-game mail later",
        ensureTheSafety: "To ensure the safety of your account and the successful recharge of your items, please click the button below to complete the in-game verification first.",
        GoVerify: "Go Verify",
        VerificationSuccessful: "Verification successful, you can now participate in point redemption.",
        VerificationFailed: "Verification failed, please log in to the correct account and try again.",
        Daily: "Daily",
        Weekly: "Weekly",
        Monthly: "Monthly",
        Time: "Time",
        RedeemItems: "Redeem items",
        ProductImage: "Product Image",
        ProductName: "Product Name",
        RedeemQuantity: "Redeem Quantity",
        ConsumePoints: "Consume Points",
        NoDataTemporarily: "No data temporarily",
        Game: "Game",
        EnsureThatTheItems: "To ensure that the items can be successfully recharged into your account, please confirm your current latest game level.",
        GameLevel: "Game Level",
        PleaseSelect: "Please Select",
        LevelError: "Level error, please verify in the game and re-enter",
        TryAgainTomorrow: "Level error, please try again tomorrow",
        RedeemNow: "Redeem Now",
        Confirm: "Confirm",
        Back: "Back",
        ReturnToRedeem: "Return to Redeem",
        EndlessTreasure: "Endless Treasure",
        Free: "Free",
        PreviousStepFirst: "Please unlock the previous step first",
        OperationSuccessful: "Operation successful",
        YouWantToOverwrite: "Your account logged in on the website does not match the one in the game, please confirm if you want to overwrite.",
        QRCodeTip: 'In order to ensure the security of your account, please log in using your Facebook, Google, or Apple account (which needs to be linked in the game) so that the props can be successfully recharged into your account. Alternatively, copy the link below and open it on your phone or use your phone to scan the QR code and then click "Redeem Now".',
        WebsiteLink: "Website link:",
        PointsGift: "Points Gift",
        AccumulatedPoints: "Accumulated Points",
        AccumulatedPoints1: "Points",
        FreeToClaim: "Free to Claim",
        AlreadyClaimed: "Already Claimed",
        ActivityRules: "Activity Rules",
        EventTime: "Event Time: June 3, 2024 - June 13, 2024 23:59:59 (UTC+0)",
        EventTime6: "Event Time: ",
        EventTip1: "1. During the event, recharge in the official store to receive corresponding points. When the points reach a certain amount, you can unlock different rewards. Each player can only claim each reward once.",
        EventTip2: "2. The points obtained can only unlock rewards during the event. After the event ends, you can no longer claim them.",
        EventTip3: '3. This event is an additional bonus. The points obtained do not conflict with the "Points Exchange" function below, meaning that for every recharge, you get double points.',
        EventTip4: "4. Ensure that rewards are successfully recharged to your account. We recommend logging in using your Facebook, Google, or Apple account that is already linked in the game.",
        EventTip5: "5. Points are non-refundable welfare. If you encounter any problems, please contact us through the customer service portal.",
        collectionsHasReachedTheLimit: "The number of collections has reached the limit",
        LimitedTimeWelfareActivities: "Limited-time Welfare Activities",
        riskControlSystem: "Your behavior has triggered the risk control system, please contact customer service if you have any questions.",
        GiftPack: "Gift Pack",
        EnterGiftRedemptionCode: "Enter gift code",
        Rules: "Rules",
        TheGiftCodeHasExpired: "The gift pack code has expired",
        TheGiftCodeIncorrect: "The gift pack code is incorrect",
        ExchangeRules: "Exchange Rules",
        ExchangeRules1: "1. Each redemption code can only be used once and is valid within a specified time, please use it within the valid period.",
        ExchangeRules2: "2. Please ensure that you have successfully logged into your game account on the official website.",
        ExchangeRules3: "3. Go to the gift package exchange page on the official website, enter or paste the redemption code you received, and click submit.",
        ExchangeRules4: "4. The system will automatically verify the validity of the redemption code. After successful redemption, please check your in-game mail and collect it.",
        ExchangeRules5: "5. Please ensure that the redemption code you entered is accurate and error-free to avoid exchange failure due to input errors.",
        ExchangeRules6: "Wish you a pleasant gaming experience! If you have any questions or doubts, please feel free to contact our customer service support. Thank you for your support and participation!",
        DiamondPackage: "Diamond Package",
        BenefitPackage: "Benefit Package",
        PointsShopTutorial: "Points Shop Tutorial",
        PointsGivingRules: "Points Giving Rules",
        PointsGivingRules1: "1. During the event, enter the official website recharge page, recharge to get points.",
        PointsGivingRules2: "2. Different recharge amounts will result in different points. You can check the number of points under the Bonus section at the bottom of the product before recharging.",
        PointsGivingRules3: "3. Products that are not marked with points do not receive points after purchase.",
        PointsExchangeTutorial: "Points Exchange Tutorial",
        PointsExchangeTutorial1: '1. Step 1: Click on the bottom tab of the 【Points Shop】 to enter the Points Mall page, click on the "Official Website Popup" or "Top Banner" to enter;',
        PointsExchangeTutorial2: '2. Step 2: If not logged in, it is recommended to use Facebook, Google, Apple already bound in the game, or click on "Game" to automatically log in to the game;',
        PointsExchangeTutorial5: '2. Step 2:If not logged in, click "Game" to start the game and automatically log in, or enter UID to log in.',
        PointsExchangeTutorial3: '3. Step 3: If already logged in, based on the "current points" in the account, select the item, click on "Exchange Now" to exchange. If it is the first time to exchange, a "verification popup" may appear, click on "Verify" to proceed;',
        PointsExchangeTutorial4: "4. Step 4: After successful exchange, please go to the game to check the email and collect it.",
        PointUsageRules: "Point Usage Rules",
        PointUsageRules1: "1. Points are bound to the account and cannot be transferred, gifted, or withdrawn.",
        PointUsageRules2: "2. Points are a unique benefit for recharging on the official website, points are not given for recharging in-game, and points cannot be used in the game.",
        Announcement: "Announcement",
        Announcement1: "1. We will regularly update the exchange items, first come first served~",
        Announcement2: "2. The item exchange restrictions will be reset on the first day of the next cycle (UTC+0).",
        Announcement3: "3. If you have reached the maximum exchange limit for this week/month, please continue to participate in the exchange activities next week/month.",
        Redeem: "Redeem",
        GiftCode: "Gift Code",
        CollectionSuccessful: "Collection successful, please check your email in the game later.",
        CollectionSuccessful2: "🎉 Official website event is now open, hurry to the welfare center to claim rewards, limited time offer of 10%-200% bonus, and also have exclusive official website customized skins and other big prizes.",
        JoinNow: "Join Now",
        PremiumCenter: "Premium Center",
        DesktopShortcut: "Shortcuts",
        AddDesktopOpen: "Add to Desktop and Open",
        LoginReward: "Login Reward",
        LogTOPCenter: "Log in to the TOP-UP Center",
        FirstPurchase: "First Purchase",
        CompleteTheFirstPurchase: "Complete the first purchase",
        ClaimNow: "Claim Now",
        COMPLETE: "COMPLETE",
        DailyCheckIn: "Daily Check-in",
        Day: "Day {day}",
        CheckIn: "Check-in",
        Note: "Note: If you check in for 7 consecutive days, you will start from Day 1 again",
        TotalCheckIns: "Total Check-ins",
        Days: " days",
        CheckCeceive: "Check in for {0} days to receive",
        CheckedForDays: "Checked in for {0} days: ",
        OneClickReceive: "One-click receive",
        PleaseTomorrow: "Please come to collect it tomorrow",
        PurchaseGift: "Purchase Gift",
        AccumulatedPurchase: "Total Purchase",
        InsufficientReward: "Insufficient amount, unable to receive the reward",
        EventPeriod: "Event period: ",
        EventPeriod1: "1. During the event, in the official recharge store, reaching the specified amount of cumulative recharge can unlock rewards. Each player can only receive each reward once.",
        EventPeriod2: "2. You need to log in to your account and verify your game ID to view the event progress and claim rewards.",
        EventPeriod3: "3. After the event ends, you can no longer claim rewards, and related tasks cannot be completed.",
        Events: "Events",
        redeemedAll: "You have redeemed all, please come back in the next cycle.",
        reconnectNetwork: "You are currently offline, please reconnect to the network.",
        Recharge: "Recharge",
        Receive: "Receive",
        Completed: "Completed",
        PurchaseReward: "Purchase Reward",
        GetFreeSkin: "Get Free Skin",
        CurrentlyNoActivities: "Currently no activities",
        EventsTips: "Only recharges at official websiteTOP-UP center will count towards the task."
    },
    Mergeland: {
        text1: 'Adapted from the novel "Alice\'s Adventures in Wonderland" and the fantasy film "Alice Through the Looking Glass", this game brings these widely-known scenes and characters of Wonderland under the spotlight.',
        text12: "You can follow the footsteps of Ally to revisit the mysterious land, trying to save White Rabbit and Mad Hatter while bringing Wonderland back to life.",
        text2: "Highlights",
        text3: "The array of classical scenes with exquisite design brings players an immersive adventure experience, leading them into the mysterious Wonderland where the wilderness, forests and palaces also deliver the power of magic.",
        text4: "The integration of Merge and Repair gameplays enables players to accumulate the necessary elements to restore Wonderland. As the levels of elements upgrade, the once-broken Wonderland will be restored to its former glory.",
        text5: 'Thanks to the variety of activities and holiday dungeons, players can immerse themselves in fairy tales like "Cinderella" and "Sleeping Beauty" while decorating their exclusive Wonderland by acquiring the skins of characters and restorations from the in-game challenges.'
    }
}