<template>
  <div id="user_agreement">
    <top-title/>
    <main-agreement-content/>
  </div>
</template>

<script>
import TopTitle from "@/views/user_agreement/child/TopTitle";
import MainAgreementContent from "@/views/user_agreement/child/MainAgreementContent";
export default {
  name: "UserAgreement",
  components: {
    TopTitle,
    MainAgreementContent
  }
}
</script>

<style scoped>
  #user_agreement {
    /*font-family: Lato, sans-serif!important;*/
  }
</style>