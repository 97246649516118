import axios from "axios";
const basicJson = require('../config');
const baseURL = basicJson.apiUrl;
// const baseURL = `https://e45nic2vv7.execute-api.us-east-1.amazonaws.com/default/payment`;

export function request(config) {
    const instance = axios.create({
        baseURL: baseURL,
        timeout: 20*1000,
        headers: {
            "Content-Type": "application/json;charset=utf-8"
        }
    });

    instance.interceptors.request.use(config => {
        return config
    }, error => {
        console.log(error);
    });

    instance.interceptors.response.use(res => {
        return res.data;
    }, error => {
        console.log(error);
        return error;
        // const delay = new Promise(resolve => {
        //     setTimeout(() => {
        //         resolve()
        //     }, 20000);
        // });
        // if (error.message === "canceled") {
        //     return error; // 直接结束，不等待响应
        // }
        // // 重新发起请求
        // return delay.then(function () {
        //     return request(error.config);
        // });
    });
    return instance(config);
}

export function requestUrl(url, config) {
    const instance = axios.create({
        baseURL: url,
        timeout: 20*1000,
        headers: {
            "Content-Type": "application/json;charset=UTF-8"
        }
    });

    instance.interceptors.request.use(config => {
        return config
    }, error => {
        console.log(error);
    });

    instance.interceptors.response.use(res => {
        return res.data;
    }, error => {
        console.log(error);
        return error;
        // const delay = new Promise(resolve => {
        //     setTimeout(() => {
        //         resolve()
        //     }, 20000);
        // });
        // if (error.message === "canceled") {
        //     return error; // 直接结束，不等待响应
        // }
        // // 重新发起请求
        // return delay.then(function () {
        //     return request(error.config);
        // });
    });
    return instance(config);
}