<template>
  <div id="main_content">
    <div id="main_description">
      <p id="main_title"><strong>{{agreementPageData.title}}</strong></p>
      <p class="title_desp">This Privacy Policy explains how <strong>Merge Neverland</strong> (or “we”, “us”, “our”, “service”) collect, use, store and share your personal information that you provide or we collect through our Service. This Privacy Policy is subject to any service agreement between <strong>Merge Neverland</strong> and you. By using <strong>Merge Neverland</strong> Service, you acknowledge that you have read and understood this Privacy Policy and you are agreeing to the collection and use of your personal information as outlined in this Privacy Policy. We may amend the Privacy Policy from time to time, and we encourage you to consult the Privacy Policy regularly for changes. Please review this Privacy Policy before using <strong>Merge Neverland</strong> Service. We are not responsible for the actions of third party people or companies, the content of their sites, the use of information you provide to them, or any products or services they may offer. Any links to those sites do not constitute our sponsorship of, or affiliation with, those people or companies.</p>
      <div class="description_list"
           v-for="(despItem, index) in agreementPageData['content']"
           :key="index">
        <p :class="[despItem.isTitle?'despClassTitle':'despClassItem']">
          <span>{{despItem.content}}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainContent",
  data() {
    return {
      agreementPageData: {
        title: 'Privacy Policy',
        content: [
          {
            isTitle: true,
            content: "1. INTRODUCTION"
          },
          {
            isTitle: false,
            content: "This Privacy Policy of Merge Neverland applies to information collection and use including while you are visiting and using Merge Neverland Service."
          },
          {
            isTitle: true,
            content: "2. THE INFORMATION WE COLLECT"
          },
          {
            isTitle: true,
            content: "A. Information your directly and voluntarily provide to us"
          },
          {
            isTitle: false,
            content: "We may collect information that you provide to us. The information includes but is not limited to: your email address, the content of feedback, and the content of questionnaire answered by you. We collect this information in a number of ways, including when you enter it while using our service, interact with our Customer Service, or participate in surveys or marketing promotions."
          },
          {
            isTitle: true,
            content: "B. Information automatically collected when you use our Service"
          },
          {
            isTitle: false,
            content: "We will collect information including, but not limited to: Your mobile device’s brand, model, operating system, resolution, screen size, system version, internet connection, RAM, history, mobile device’s Android Advertising ID, along with your account activation time, content displayed to you and whether you clicked on it, advertisements displayed to you and whether you clicked on them, notifications that have appeared on your mobile device, your IP address, your mobile country code. Your device ID."
          },
          {
            isTitle: true,
            content: "C. Information collected by our Third Party Advertising Partners."
          },
          {
            isTitle: false,
            content: "We work with third party advertising partners to provide personalized ads for you."
          },
          {
            isTitle: false,
            content: "Our partners may collect hardware and software information such as IP address, device ID and type, advertising IDs (such as Google's AAID and Apple's IDFA, both of which are randomly generated numbers that you can reset by going into your device' settings), device language, operating system, time zones, and identifiers associated with cookies or other technologies that may uniquely identify your device."
          },
          {
            isTitle: true,
            content: "D. Information collected by Third Parties Analytics Services"
          },
          {
            isTitle: false,
            content: "We may work with third party analytics services to help us provide you with better service. Information might be analyzed such as how often you use the application and the events that occur within the application. We collect this type of analysis information and use it in conjunction with the analytical information collected from other users so that such information cannot be used to identify any specific individual user."
          },
          {
            isTitle: false,
            content: "If you give us personal information of somebody else, we will assume that you have their permission to do so. Transmissions to and from Merge Neverland or the Site may not be confidential and consequently, may be read or intercepted by others."
          },
          {
            isTitle: true,
            content: "3. HOW WE USE THE COLLECTED INFORMATION"
          },
          {
            isTitle: false,
            content: "We collect, store and use your information in order to provide you with a safe, smooth, efficient, fun, and customized experience. We may use information collected from you in any one or more of the following ways:"
          },
          {
            isTitle: false,
            content: "A. To create game accounts and allow users to play our games;"
          },
          {
            isTitle: false,
            content: "B. To solicit input and feedback to improve Merge Neverland products and services and customize your user experience;"
          },
          {
            isTitle: false,
            content: "C. To communicate with you about promotions, rewards, upcoming events, and other news about products and services offered by Merge Neverland and our selected partners;"
          },
          {
            isTitle: false,
            content: "D. To prevent fraud or potentially illegal activities, and enforce our Terms of Service;"
          },
          {
            isTitle: false,
            content: "E. To link or combine it with other information we get from third parties, to help understand your preferences and provide you with better services;"
          },
          {
            isTitle: false,
            content: "H. To give you opportunity to invite your contacts from a SN Service (such as your Facebook friends) so that those contacts can be located in Merge Neverland games or you can invite them to join you in Merge Neverland games;"
          },
          {
            isTitle: false,
            content: "We will ask for your consent before using your information for a purpose that is not covered in the Policy."
          },
          {
            isTitle: true,
            content: "4. SHARING OF YOUR PERSONAL INFORMATION"
          },
          {
            isTitle: true,
            content: "Apart from Merge Neverland, your information can be accessed by others in the following situations:"
          },
          {
            isTitle: true,
            content: "A. Other players and users"
          },
          {
            isTitle: false,
            content: "Social features are a core component of our games. Other players and users may, for example, see your profile information, in-game activities and read the messages you have posted."
          },
          {
            isTitle: true,
            content: "B. Third party service providers"
          },
          {
            isTitle: false,
            content: "We provide your personal information to other companies, agents or contractors (\"Service Providers\") to perform services on our behalf or to assist us with the provision of services to you, based on our instructions and in compliance with the Policy. For example, we engage Service Providers to provide marketing, advertising, to personalize and optimize our service, to provide customer service, to analyze and enhance information (including information about users' interactions with our service), and to process and administer consumer surveys. In the course of providing such services, these Service Providers may have access to or processed your personal or other information. We will confirm that service providers can provide sufficient guarantees regarding the confidentiality and security of your information."
          },
          {
            isTitle: true,
            content: "C. Others"
          },
          {
            isTitle: false,
            content: "If we are required to disclose your personal information by law, court order or governmental authority, we may do so to the extent that is necessary for us to abide to our legal duties and obligations. In such case, you will be notified in a prompt manner."
          },
          {
            isTitle: false,
            content: "In the event that Merge Neverland undergoes a business transition, such as a merger, acquisition by another company, or sale of all or a portion of its assets, we may share or transfer all of your information, to the successor organization. If material changes to Merge Neverland' privacy practices occur as a result of the business transition, you will be notified via e-mail or notice on our site of the business transition prior to transferring your personal information. If you do not agree with such material changes, you are entitled to direct us not to transfer your personal information."
          },
          {
            isTitle: false,
            content: "We do not actively share personal information with third party advertisers for their direct marketing purposes unless you give us your consent. We may share (i) aggregated information (information about you and other users collectively, but not specifically identifiable to you); (ii) anonymous information; and (iii) certain technical information (including IP Addresses, MAC Addresses for mobile devices and mobile device IDs) to develop and deliver targeted advertising in the Service and on the websites of third parties."
          },
          {
            isTitle: false,
            content: "Our collection, use, and disclosure of anonymous or aggregated information are not subject to any of the restrictions in this Privacy Policy."
          },
          {
            isTitle: true,
            content: "5. TRANSFERS OF PERSONAL INFORMATION OUTSIDE THE EU/EUROPEAN ECONOMIC AREA"
          },
          {
            isTitle: false,
            content: "We share your personal information with our affiliates and partners in the EU/EEA and outside the EU/EEA. Where a data transfer outside the EU/EEA is not covered by an EU Commission adequacy decision, we rely on lawful safeguards referred to in Art 46 et. seqq. GDPR. This mainly includes EU Commission-approved Standard Contractual Clauses which we enhanced by supplementary security measures such as ancillary individual risk-assessments, additional contractual safeguards and technical safeguards including additional encryption or pseudonymisation, to enable International transfers with our affiliates and partners outside the EU/EEA. You will find the full text of the EU Commission-approved Standard Contractual Clauses through this website, https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en. Please feel free to contact us for additional information on third country data transfers as well as our safeguards and supplementary security measures."
          },
          {
            isTitle: false,
            content: "We share personal information with external vendors or service providers or suppliers that we engage to perform services or functions on our behalf and under our instructions. Where these vendors are located within the EU, we ensure that they are contractually obligated to comply with the EU data protection rules. We also ensure in our contracts with these organisations that they only Process Personal information in accordance with our instructions and in order to provide the agreed services and protect the integrity and confidentiality of your personal information entrusted to them."
          },
          {
            isTitle: false,
            content: "We may also disclose personal information to our advisers, consultants, law enforcement and other public authorities (such as tax and social security bodies), the police, prosecutors, courts and tribunals. All these recipients are themselves responsible to comply with the EU data protection rules."
          },
          {
            isTitle: false,
            content: "Some of the vendors that we engage to are located outside the European Economic Area. Where the EU Commission did not recognise them as locations providing adequate protection for personal information, we rely on lawful safeguards as described above."
          },
          {
            isTitle: true,
            content: "6. YOUR RIGHTS"
          },
          {
            isTitle: false,
            content: "Upon your request, you may access to your personal information on the device at any time, including but not limited to your account information, contact information, and all kinds of relevant information of the account, or to correct or update our outdated or inaccurate personal information about you. If you find that we collect and use your personal information in violation of laws, administrative regulations or the agreement of both parties, you can ask us to delete it. Moreover, when the personal information that we have collected from you are no longer necessary for the purpose for which they were collected, you can ask us to delete it as well. To make requests, or if you have any other question regarding your rights, please contact us via mergeneverland@gmail.com. We will respond to all requests within a reasonable timeframe."
          },
          {
            isTitle: false,
            content: "We may reject requests that are unreasonable or not required by law, including those that would be extremely impractical, could require disproportionate technical effort, or could expose us to operational risks such as free trial fraud. We may retain information as required or permitted by applicable laws and regulations, including to honor your choices, for our records purposes and to fulfill the purposes described in this Privacy Policy. We take reasonable measures to destroy or de-identify personal information in a secure manner when it is no longer required."
          },
          {
            isTitle: false,
            content: "When you access, modify, or delete information, we may require you to authenticate to protect the security of your account. We promise that, in the use of the Services, you will not be discriminated against by exercising your rights."
          },
          {
            isTitle: true,
            content: "7. YOUR RIGHTS UNDER THE GDPR"
          },
          {
            isTitle: false,
            content: "You are entitled to obtain information from us on how we handle your personal information, to see copies of all personal information held by us and to request that your personal information is amended, corrected or deleted from our systems. You can also limit, restrict or object to the processing of your information."
          },
          {
            isTitle: false,
            content: "We do not carry out any decision-making based solely on automated processing, including profiling."
          },
          {
            isTitle: false,
            content: "If you gave us your consent to use your data, e.g. so that we can send you marketing emails or display personalised ads, you can withdraw your consent at any time. Please note that even if you withdraw your consent, we can still rely on the consent you gave as the lawful basis for processing your personal information before you withdrew your consent."
          },
          {
            isTitle: false,
            content: "You can object to our use of your personal information where we stated we rely on our legitimate business interests to do so."
          },
          {
            isTitle: true,
            content: "8. YOUR OPTIONS"
          },
          {
            isTitle: true,
            content: "A.  Push Notifications."
          },
          {
            isTitle: false,
            content: "You can choose to receive mobile push notifications from Merge Neverland. If you subsequently decide you no longer wish to receive these notifications, you can use your mobile device's settings functionality to turn them off."
          },
          {
            isTitle: true,
            content: "B.  Interest-Based Ads."
          },
          {
            isTitle: false,
            content: "Interest-based ads are online ads that are tailored to your likely interests based on your use of various apps and websites across the Internet. If you are using a browser or app, then cookies and web beacons can be used to collect information to help determine your likely interests. If you are using a mobile device, tablet, or streaming media device that includes an advertising identifier, then that identifier can be used to help determine your likely interests. For your choices about interest-based ads from Merge Neverland, please see the \"COOKIES\" section (below)."
          },
          {
            isTitle: true,
            content: "9.  CALIFORNIA RESIDENTS’ RIGHTS"
          },
          {
            isTitle: true,
            content: "A. CCPA"
          },
          {
            isTitle: false,
            content: "In this section, we provide additional information about how we handle personal information about California residents as required under California privacy laws including the California Consumer Privacy Act (“CCPA”). This section does not address or apply to our handling of publicly available information lawfully made available by state or federal government records or other personal that is exempt under the CCPA."
          },
          {
            isTitle: false,
            content: "Under the CCPA, a “sale” includes disclosing or making available to a third party personal information, in exchange for monetary compensation or some other value or benefit. While we do not disclose personal information in exchange for monetary compensation, we may make certain categories of personal information available in order to receive certain benefits or services, such as when we make browsing information available to third party ad companies (through third party tags on our sites) in order to improve and measure our ad campaigns and reach users with more relevant ads and content."
          },
          {
            isTitle: false,
            content: "We may share your information with other players (your in-game nickname and avatar only), our business partners, service providers, affiliates and subsidiaries, advertising and promotional agencies and networks, consultants and those organisations or online platforms selected by us to carry out marketing campaigns on our behalf and to advertise their own products or services that may be of interest to you."
          },
          {
            isTitle: true,
            content: "B.      CCPA Rights."
          },
          {
            isTitle: false,
            content: "In general, California residents have the following rights with respect to your personal information:"
          },
          {
            isTitle: false,
            content: "Do-not-sell (opt-out): to opt-out of our sale of your personal information. While we may “sell” personal information as defined by the CCPA, we do not sell personal information about California consumers who we know are younger than 13 years old."
          },
          {
            isTitle: false,
            content: "Right of deletion: to request deletion of their personal information that we have collected about you and to have such personal information deleted (without charge), subject to certain exceptions."
          },
          {
            isTitle: false,
            content: "Right to know: with respect to the personal information we have collected about you in the prior 12 months, to require that we disclose the following to you (up to twice per year and subject to certain exemptions):"
          },
          {
            isTitle: false,
            content: "·   categories of personal information collected;"
          },
          {
            isTitle: false,
            content: "·   categories of sources of personal information;"
          },
          {
            isTitle: false,
            content: "·   categories of personal information about you we have disclosed for a business purpose [or sold];"
          },
          {
            isTitle: false,
            content: "·   categories of third parties to whom we have sold or disclosed for a business purpose your personal information;"
          },
          {
            isTitle: false,
            content: "·   the business or commercial purposes for collecting or selling personal information; and"
          },
          {
            isTitle: false,
            content: "·   a copy of the specific pieces of personal information we have collected about you.\nRight to non-discrimination: the right not to be subject to discriminatory treatment for exercising your rights under the CCPA.\nSubmitting CCPA Requests. California residents may submit CCPA requests through one of the following methods:"
          },
          {
            isTitle: false,
            content: "·   Through our service; or"
          },
          {
            isTitle: false,
            content: "·   By contacting us via as set herein."
          },
          {
            isTitle: false,
            content: "When you submit any request, we will take steps to verify your request by asking you some questions about in-game experience and matching the information provided by you with the information we have in our records. You must answer the questions to verify your request. In some cases, we may request additional information in order to verify your request or where necessary to process your request. If we are unable to adequately verify a request, we will notify the requestor. Authorized agents may initiate a request on behalf of another individual by contacting us via as set forth above. Authorized agents will be required to provide proof of their authorization and we may also require that the relevant consumer directly verify their identity and the authority of the authorized agent."
          },
          {
            isTitle: false,
            content: "For more information about our privacy practices, you may contact us as set forth above."
          },
          {
            isTitle: true,
            content: "10. COOKIES"
          },
          {
            isTitle: false,
            content: "A cookie is a small text file that we transfer to your computer or mobile devices’ hard disk, to quickly identify a user's computer or mobile devices and to \"remember\" things about your visit, such as your preferences or a user name and password. Information contained in a cookie may be linked to your personal information, such as your user ID, for purposes such as improving the quality of our Service, tailoring recommendations to your interests, making the Service easier to use, and managing advertising."
          },
          {
            isTitle: false,
            content: "We may feature advertisements served by third parties that deliver cookies to your computer or mobile devices so the content you access and advertisements you see can be tracked. Since the third-party advertising companies associate your computer or mobile devices with a number, they will be able to recognize your computer or mobile devices each time they send you an advertisement. These advertisers may use information about your visits to our Service and third-party sites and applications in order to measure advertisement performance and to provide advertisements about goods and services of interest to you. We have no access to or control over the information collection practices of these third-party advertisers. You can obtain further information about third party privacy policies or disable their cookies by visiting their web sites."
          },
          {
            isTitle: false,
            content: "Your use of our websites or application indicates your consent to the cookies we have described in this Policy. You can disable cookies at any time. However, in the case that the use of cookies is for the purpose of performing our Services, your rejection may lead to the failure of using our Services."
          },
          {
            isTitle: false,
            content: "If our use of cookies changes, we will post the changes to this policy. Please check frequently as online information collection practices and our use of them continue to evolve. To contact us about this policy, you may email mergeneverland@gmail.com."
          },
          {
            isTitle: true,
            content: "11. SECURITY"
          },
          {
            isTitle: false,
            content: "We take reasonable administrative, logical, physical and managerial measures in order to protect your personal information against loss, theft and unauthorized access, use and modification. These measures are designed to provide a level of security appropriate to the risks of processing your personal information. For example, we use firewalls, encryption technology and other automated software designed to protect against fraud and identity theft. We strictly prohibit any user unreasonably using the privacy of others obtained during the use of the Services."
          },
          {
            isTitle: true,
            content: "12. AGE LIMITS"
          },
          {
            isTitle: false,
            content: "We do not knowingly collect any personal information from such children. In the event that we learn that we have inadvertently gathered personal information from children under the age of 13, we will take reasonable measures to promptly erase such information from our records. If you believe that we might have any information from or about a child under the age of 13, please contact us at mergeneverland@gmail.com."
          },
          {
            isTitle: true,
            content: "13. CHANGE TO THE PRIVACY POLICY"
          },
          {
            isTitle: false,
            content: "We will update our Privacy Policy from time to time in response to changing legal, regulatory or operational requirements. We will provide notice of any changes (including when they will take effect) in accordance with law. We will not reduce your rights under this Privacy Policy without your explicit consent. Your continued use of the Merge Neverland service after any such updates take effect will constitute acknowledgement and (as applicable) acceptance of those changes. If you do not wish to acknowledge or accept any updates to this Privacy Policy, you may cancel your use of the Merge Neverland service."
          },
          {
            isTitle: true,
            content: "14. LANGUAGE"
          },
          {
            isTitle: false,
            content: "The controlling language of this Privacy Policy is English, any provided translation of this Privacy Policy is for purposes of convenience only and the English version shall govern to the extent of any inconsistency."
          },
          {
            isTitle: true,
            content: "15. CONTACT US"
          },
          {
            isTitle: false,
            content: "If you have any question or comment about this Privacy Policy, please contact mergeneverland@gmail.com."
          },
          {
            isTitle: false,
            content: "Please be sure to include your personal name (and the full company name, if you are inquiring on behalf of such company), address and email address in any correspondence to us so that we can respond to your inquiry in a timely manner."
          }
        ]
      }
    }
  },
}
</script>

<style scoped lang="scss">
  #main_content {
    padding: 0 58px;
    margin-bottom: 66px;
    #main_description {

      #main_title {
        color: #666367;
        text-align: center;
        margin: 23px 0 3px;
        font-size: 19px;
        span {
          font-family: Lato, sans-serif ;
        }
      }
    }
  }
  .despClassTitle {
    color: #000000;
    //font-family: Arial;
    font-weight: 700;
    margin-top: 12px;
  }
  .despClassItem {
    color: #000000;
    //font-family: Arial;
    font-weight: 400;
    margin-top: 12px;
  }


  /* 适配移动端 */
  @media screen and (max-width: 800px){
    #main_content {
      padding: 0 25px;
    }
    //.main_title  {
    //  span {
    //    font-size: 50px;
    //  }
    //}
  }
</style>