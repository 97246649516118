

// 打开 URL 链接
function openUrl(url, isCurrent=false) {
    if (isCurrent) {
        window.location.href = url
    } else {
        const aTag = document.createElement('a'); // 创建一个超链接
        aTag.href = url;  // 索引地址
        aTag.target = "_blank";   // 跳转到一个新的界面
        aTag.style.display = 'none';   // 不显示这个超链接标签
        document.body.appendChild(aTag);    // 将标签添加到 document 文档中
        aTag.click();   // 模拟用户点击
        document.body.removeChild(aTag);  // 从文档中移除这个标签
    }
}

// 提取 URL 中所有的参数形成键值对
function withdrawParamsToObj(url) {
    const query_string_start = url.indexOf("?")
    if (query_string_start === -1) {
        return {}  // 没有查询字符串，返回空字典
    }
    const query_string = url.slice(query_string_start + 1);
    const params = {}
    for (const param_pair of query_string.split("&")) {
        const paramItem = param_pair.split("=");
        params[paramItem[0]] = paramItem[1];
    }
    return params
}


module.exports = { openUrl, withdrawParamsToObj }