<template>
  <div id="alert_msg" ref="alertArea">
    <card-comp>
      <div id="mask" @click="hiddenAlert(true)"></div>
      <div id="alert_box">
        <div id="alert_title">
          {{title}}
        </div>
        <div id="alert_content">
          {{alertContent}}
        </div>

        <div id="btn_area">
          <div id="btn_ensure" @click="hiddenAlert(true)">确定</div>
        </div>
      </div>
    </card-comp>
  </div>
</template>

<script>
import CardComp from "@/components/CardComp";
export default {
  name: "AlertMsg",
  computed: {
    // 提示标题
    title() {
      return this.$store.state.alertMsg.alertTitle;
    },
    // 提示主体内容
    alertContent() {
      return this.$store.state.alertMsg.alertContent;
    }
  },
  components: {
    CardComp
  },
  methods: {
    // 隐藏提示弹窗
    hiddenAlert(isHidden) {
      if (isHidden) { // 清空提示内容
        this.$store.commit("changeAlertMsg", {"alertContent": ""});
      }
      this.$refs.alertArea.style.display = isHidden?"none":"block";
    }
  },
  watch: {
    alertContent(newMsg) {
      if (newMsg) {
        this.hiddenAlert(false);      // 显示提示框
      }
    }
  }
}
</script>

<style scoped>
  /*#alert_msg {*/
  #alert_msg {
    display: none;
  }
  #mask {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: #000;
    opacity: 0.3;
    filter: alpha(opacity: 30);
    /*background: rgba(167, 168, 172, 1);*/
    z-index: 888;
  }
  #alert_box {
    position: fixed;
    width: 100%;
    max-width: 400px;
    height: 200px;
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50% );
    z-index: 889;

    border-radius: 15px;
  }
  div#alert_title {
    font-size: large;
    color: #f56c6c;
    font-weight: 600;
    padding: 10px 15px;
  }
  div#alert_content {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 50%;
    padding: 5px 15px;
  }
  div#btn_area {
    display: flex;
    flex-flow: row nowrap;
    /* align-items: flex-end; */
    justify-content: flex-end;
    padding: 5px 15px;
  }
  div#btn_ensure {
    background-color: #79bbff;
    padding: 5px 15px;
    color: #fff;
    font-weight: 600;
    border-radius: 5px;
    margin-right: 24px;
    cursor: pointer;
  }
</style>