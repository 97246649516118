import { createI18n } from 'vue-i18n';
import en from "./en";

export const i18n = createI18n({
    locale: 'en',       // 语言标识
    messages: {
        en
    },
})

const loadedLanguages = ['en'];

export async function changeLanguage(lang) {
    // Check if the language is already loaded
    if (!loadedLanguages.includes(lang)) {
      try {
        const mod = await import(`./${lang}.js`);
        i18n.global.setLocaleMessage(lang, mod.default);
      } catch (error) {
        console.error(`Failed to load language ${lang}:`, error);
        return;
      }
    }
    i18n.locale = lang; // Change the language
    return true;
  }