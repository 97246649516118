<template>
    <div>
        <div style="display: none;">{{ isLogin }}</div>
        <p class="contentTitle">{{ $t('center.PointsExchange') }}</p>
        <div style="margin-bottom: 10px;">{{ $t('center.CurrentPoints') }}: <span class="tuText">{{ points }}</span></div>
        <div class="pointsList">
            <point-shop-item v-for="it, i in data.items" :key="i" :title="it.goodTitle" :item-sku="it.point_id" :point="Number(it.point)" :current-count="it.point_id in limits ? limits[it.point_id] : 0" :max-count="it.times === '' ? 99 : Number(it.times)" :limited="it.goodlimited" :selected="selectedItem === i" :icon="require(`@/assets/imagine/goods/${it.goodCover}`)" @click="selectedItem = i" @redeem="redeemNow"></point-shop-item>
        </div>
        <div class="FAQ">
        <div class="instructions">
          <div class="contentTitle announcement">{{ $t('center.PointsGivingRules') }}</div>
          <ol class="instrList">
            <li class="instr">{{ $t('center.PointsGivingRules1') }}</li>
            <li class="instr">{{ $t('center.PointsGivingRules2') }}</li>
            <li class="instr">{{ $t('center.PointsGivingRules3') }}</li>
          </ol>
          <div class="contentTitle announcement">{{ $t('center.PointUsageRules') }}</div>
          <ol class="instrList">
            <li class="instr">{{ $t('center.PointUsageRules1') }}</li>
            <li class="instr">{{ $t('center.PointUsageRules2') }}</li>
          </ol>
          <div class="contentTitle announcement">{{ $t('center.Announcement') }}</div>
          <ol class="instrList">
            <li class="instr">{{ $t('center.Announcement1') }}</li>
            <li class="instr">{{ $t('center.Announcement2') }}</li>
            <li class="instr">{{ $t('center.Announcement3') }}</li>
          </ol>
        </div>
      </div>
    </div>
</template>

<script>
import PointShopItem from '@/views/topup/PointShopItem.vue';
import { redeem, getItemLimited } from '@/request/pay_point';
import { mapState } from 'vuex';

export default {
    name: "PointShop",
    data() {
        return {
            selectedItem: 0,
            limits: {},
            loadingLimits: false,
            points: 0
        }
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    components: {
        PointShopItem
    },
    computed: {
        ...mapState({
            isLogin: state => state.isLogin
        })
    },
    watch: {
        isLogin(newVal, oldVal){
            console.log('is login ' + newVal);
            this.updateLimits();
        },
        data: {
            handler(newVal, oldVal){
                if (newVal.country === oldVal.country){
                    this.updateLimits();
                }
            },
            deep: true
        }
    },
    methods: {
        updateLimits(){
            const userMsg = this.$store.state.userMsg;    // 获取当前登录的用户信息
            if (!userMsg.appid){
                return;
            }
            if (this.loadingLimits){
                return;
            }
            this.loadingLimits = true;
            this.$store.commit("isLoadCurrent", true);
            getItemLimited(userMsg.appid, userMsg.userId, this.data.country, this.data.sandbox).then(res => {
                this.loadingLimits = false;
                if (typeof(res.data) === 'object'){
                    res.data.forEach(element => {
                        this.limits[element.point_id] = element.times;
                    });
                    this.points = ('userInfo' in res && 'point' in res.userInfo) ? res.userInfo.point : 0;
                }
                this.$store.commit("isLoadCurrent", false);           // 关闭加载动画
            });
        },
        redeemNow(pointId, count){
            if (!this.$store.state.isLogin) { return this.$store.commit("changeAlertMsg", "Please proceed with the payment after logging in and selecting the items."); }   // 如果没有登录，就要弹出提示内容
            const userMsg = this.$store.state.userMsg;    // 获取当前登录的用户信息
            this.$store.commit("isLoadCurrent", true);           // 关闭加载动画
            redeem(userMsg.appid, userMsg.userId, pointId, count, this.data.country, this.data.sandbox).then(res => {
                console.log(res);
                if (res.status != 1){
                    if (res.status === 1001){
                        this.$store.commit("changeAlertMsg", this.$t("center.InsufficientPoints"));
                    } else if (res.status === 1002){
                        this.$store.commit("changeAlertMsg", this.$t("center.reachedLimit"));
                        this.updateLimits();
                    }
                } else {
                    this.$store.commit("changeAlertMsg", this.$t('center.ExchangeSuccessful'));
                    this.updateLimits();
                }
                this.$store.commit("isLoadCurrent", false);           // 关闭加载动画
            });
        }
    },
};
</script>

<style lang="scss" scoped>
.contentTitle {
    color: #333;
    font-size: large;
    font-weight: 600;
    line-height: 1.2;
    margin-top: 18px;
    margin-bottom: 15px;
    &.announcement {
        margin-bottom: 0px;
    }
}
.pointsList {
    display: grid;
    grid-template-columns: repeat(4,calc(25% - 18px));
    grid-gap: 24px 24px;
}

.tuText {
    color: #ff6d3c;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 600;
}

@media screen and (max-width: 800px) {
    .pointsList {
        grid-template-columns: repeat(2,calc(50% - 5px));
        grid-gap: 16px 10px;
    }
}
</style>