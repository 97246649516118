const sandbox = false;
const develop = false;

const basic = {
    company: 'IVYMobile LTD.',
    email: 'mergeneverland@gmail.com',
    appid: 2749
    // appid: 10087
};

const configOrigin = {
    develop:{
        returnUrl: "https://localhost:8080",
        apiUrl:"https://localhost:8888",
        sandbox: sandbox,
    },
    sandbox: {
        returnUrl: "https://neverland.ivymobile.com",
        apiUrl:"https://webpay.ivymobile.com",
        sandbox: true,
    },
    prod:{
        returnUrl: "https://neverland.ivymobile.com",
        apiUrl:"https://webpay.ivymobile.com",
        sandbox: false,
    },
};

const config = Object.assign(basic, develop ? configOrigin.develop : (sandbox ? configOrigin.sandbox : configOrigin.prod));

module.exports = config;