<template>
  <div id="check_order" ref="alertOrder">
    <div id="mask" @click="hiddenOrderAlert(true)"></div>
    <card-comp>
      <div id="alert_box">
        <div id="alert_title">
          <img src="@/assets/imagine/topup/wrong.svg"
               alt="turn off"
               @click="hiddenOrderAlert(true)">
        </div>
        <div id="alert_content">
          <img src="@/assets/imagine/topup/success.svg"
               alt="success"
               v-if="isSuccessAlert">
          <img src="@/assets/imagine/topup/failure.svg"
               alt="failure"
               v-else>

          <span>{{alertContent}}</span>
        </div>
        <div id="btn_area">
          <div id="btn_order_ensure" @click="hiddenOrderAlert">Add to Home Screen</div>
        </div>
      </div>

    </card-comp>
  </div>
</template>

<script>
import CardComp from "@/components/CardComp";
export default {
  name: "CheckOrder",
  computed: {
    // 弹窗显示的主体内容
    alertContent() {
      return this.$store.state.checkOrder.checkResultMsg;
    },

    // 是否是成功的内容的弹窗
    isSuccessAlert() {
      return this.$store.state.checkOrder.isSuccess;
    }
  },
  components: {
    CardComp
  },
  methods: {
    // 隐藏弹窗
    hiddenOrderAlert(isHidden=true) {
      if (isHidden) {  this.$store.commit("alertCheckOrder", ""); }    // 如果隐藏弹窗
      this.$refs.alertOrder.style.display = isHidden?"none":"block";    // 弹窗是否显示
    },
  },
  watch: {
    // 监听弹出的信息，如果不为空(从其他组件中赋值)，就展示内容
    alertContent(newMsg) {
      if (newMsg) { this.hiddenOrderAlert(false); }
    }
  }
}
</script>

<style scoped>
  #check_order {
    display: none;
  }
  #mask {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: #000;
    opacity: 0.7;
    filter: alpha(opacity: 70);
    z-index: 888;
  }
  #alert_box {
    position: fixed;
    width: 100%;
    max-width: 400px;
    height: 400px;
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50% );
    z-index: 889;
    border-radius: 15px;
  }
  div#alert_title {
    font-size: large;
    color: #f56c6c;
    font-weight: 600;
    padding: 10px 15px;
    display: flex;
    flex-direction: row-reverse;
  }
  div#alert_title img {
    height: 35px;
    cursor: pointer;
  }
  div#alert_content {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 50%;
    padding: 5px 15px;
  }
  div#alert_content img {
    height: 100px;
  }
  div#alert_content span {
    color: #747474;
    margin-top: 30px;
    line-height: 1.5;
    font-size: large;
  }
  div#btn_area {
    display: flex;
    flex-flow: row nowrap;
    /* align-items: flex-end; */
    justify-content: center;
    padding-top: 32px;

  }
  div#btn_order_ensure {
    background-color: #557be0;
    padding: 13px 20px;
    color: #fff;
    font-weight: 600;
    border-radius: 13px;
    cursor: pointer;
  }
  @media screen and (max-width: 800px){
    #alert_box {
      width: 90%;
      height: 430px;
    }
    div#alert_content {
      height: auto;
    }
    div#btn_area {
      padding-top: 11px;
    }
    div#alert_content span {
      margin-top: 15px;
    }
  }
</style>