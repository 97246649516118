import { request, requestUrl } from "@/request/index";

// 点击付款后，发起支付请求，并反馈跳转链接地址
export function payWithURLXsolla(userMsg, goodsMsg, payment_method, goodsPackDetail, sandbox, appid, country) {
    payment_method = payment_method == undefined ? "0" : payment_method;
    // return requestUrl('https://webpay.iibingo.com/pay/xsolla_pay', {
    return request({
        // url: "/xsolla_pay",
        // baseURL: "http://10.80.1.176:8888",                      // 测试用

        method: "POST",
        data: JSON.stringify({
            action: "xsolla_pay",
            appid,
            sandbox,
            userMsg,
            goodsMsg,
            payment_method,
            goodsPackDetail,
            country
        })
    });
}

// 查询订单状态，使用 xsolla 支付后查看订单
export function checkOrderResultWithXsolla(userId, orderId, goodsPackDetail, sandbox) {
    return request({
        // url: "/check_xsolla_order",       // 测试用
        // baseURL: "http://10.80.1.176:8888",                      // 测试用

        method: "POST",
        data: {
            action: "check_xsolla_order",
            userId,
            sandbox,
            orderId,
            goodsPackDetail,
            // appid,
        }
    });
}