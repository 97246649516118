<template>
  <div id="app">
<!--    <header-menu/>-->
    <router-view/>
<!--    <foot-comp/>-->

<!--    <alert-msg/>-->
  </div>
</template>

<script>

// import HeaderMenu from "@/components/HeaderMenu";
// // import TopupView from "@/views/topup/TopupView"
// import FootComp from "@/components/FootComp";
// import AlertMsg from "@/components/AlertMsg";

export default {
  name: 'App',
  components: {
    // HeaderMenu,
    // // TopupView,
    // FootComp,
    // AlertMsg
  },
  created() {
  },
  methods: {

  }
}
</script>

<style>
  html, body {
    padding: 0;
    margin: 0;
  }
  #app {
    padding: 0;
    margin: 0;
  }
  li {
    list-style: none;
  }
  * {
    padding: 0;
    margin: 0;
    font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, PingFang SC, Noto Sans, Microsoft Yahei, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  }
  a {
    text-decoration: none;
  }
</style>
