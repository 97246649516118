import Vuex from "vuex";
import config from '../config'

export default new Vuex.Store({
    state: {
        isLogin: false,      // 切换登录状态(默认为未登录)
        activeMethod: 0,
        userMsg: {},        // 用户信息
        alertMsg: {             // 弹窗提示的信息
            alertTitle: 'Warning：',     // 要提示的标题
            alertContent: '弹窗提示的内容',       // 弹窗提示的内容
        },
        checkOrder: {       // 检测订单
            isSuccess: true,    // 是否是默认
            checkResultMsg: "",     // 弹窗提示的信息
        },
        isLoading: false,       // 是否正在加载什么内容(控制 Loading 动画的显示和隐藏)
        appid: config.appid,          // 应用ID
    },
    mutations: {
        // 切换登录状态
        changeLoginState(state, isLogin) {
            state.isLogin = isLogin;
        },

        // 弹窗显示的内容
        changeAlertMsg(state, msg) {
            if (typeof msg === "string") {
                state.alertMsg.alertContent = msg;
            } else {
                state.alertMsg.alertTitle = msg.alertTitle?msg.alertTitle:"Warning：";
                state.alertMsg.alertContent = msg.alertContent;
            }
        },

        // 赋值用户信息
        assignUserMsg(state, userMsg) {
            state.userMsg = userMsg;
        },

        // 修改订单完成后(可能取消订单，可能成功付款)
        alertCheckOrder(state, orderMsg) {
          if (typeof orderMsg === "string") {       // 如果是 string 格式，则传递过来的只是弹窗提示的内容
              state.checkOrder.checkResultMsg = orderMsg;
              this.state.checkOrder.isSuccess = true;       // 如果只有显示的文本内容，就默认为显示的是成功的弹窗(防止关闭弹窗后，的，二次弹窗，会显示为错误的弹窗提示)
          }else {           // 如果是对象类型
              if (!orderMsg) { return console.error("store:the params, which is check order result, is empty!"); }
              const { isSuccess, checkResultMsg } = orderMsg;       // 提取传递过来的参数
              if (typeof isSuccess !== "boolean") { return console.error("store: the params of isSuccess in checkOrder must be boolean!"); }
              if (typeof checkResultMsg !== "string") { return console.error("store: the params of checkResultMsg in checkOrder must be string!"); }

              state.checkOrder.isSuccess = isSuccess;
              state.checkOrder.checkResultMsg = checkResultMsg;
          }
        },

        // 切换是否在加载内容
        isLoadCurrent(state, isLoad=true) { // 默认是要加载
            state.isLoading = isLoad;
        }

    },
    actions: {
        toggleLoginState(context, isLogin) {
            context.commit('changeLoginState', isLogin);
        }
    },
    getters: {

    },
    modules: {

    }
})