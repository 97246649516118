<template>
  <div id="privacy_policy">
    <top-title/>
    <main-agreement-content/>
  </div>
</template>

<script>
import TopTitle from "@/views/privacy_policy/child/TopTitle";
import MainAgreementContent from "@/views/privacy_policy/child/MainAgreementContent";
export default {
  name: "PrivacyPolicy",
  components: {
    TopTitle,
    MainAgreementContent
  }
}
</script>

<style scoped>

</style>