import { request } from "@/request/index";

// 获取订单所需数据
export function getOrderParamsAirwallex(price, userId, itemSku, goodsPackDetail, payType, sandbox, appid, country) {
    return request({
        method: "POST",

        // 测试用
        // baseURL: "http://10.80.1.176:8888",
        // url: "/airwallex_order",

        data: {
            action: "airwallex_order",
            appid,
            sandbox,
            price,      // 商品价格
            userId,     // 用户 ID
            itemSku,    // 商品 sku
            goodsPackDetail,        // 要发放的商品内容
            payType,    // 付款类型
            country
        }
    })
}

// 查询订单结果
export function checkOrderResultAirwallex(paymentIntentID, sandbox) {
    return request({
        // 测试用
        // baseURL: "http://10.80.1.176:8888",     // 测试用
        // url: "/check_airwallex_order",     // 测试用

        method: "POST",
        data: {
            action: "check_airwallex_order",
            paymentIntentID,
            sandbox
            // appid
        }
    })
}