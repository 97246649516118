<template>
  <div id="card_box"
       :style="{'border-radius': isRadius?'0':'12px'}">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "CardComp",
  data() {
    return {
      isRadius: false
    }
  },
  created() {
    this.handleResize();
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    // 屏幕宽度改变的时候
    handleResize() {
      this.isRadius = window.innerWidth<=800;
    }
  },
}
</script>

<style scoped>
  #card_box {
    padding: 16px 16px;
    margin-top: 16px;
    background-color: #fff;
    box-shadow: 0 2px 7px 0 rgba(0,0,0,.15);
  }
</style>