import { request } from "@/request/index";

// 获取订单所需数据
export function getOrderParamsPayerMax(price, paymethod, org, userId, itemSku, sandbox, appid, country) {
    return request({
        method: "POST",

        // 测试用
        // baseURL: "http://10.80.1.176:8888",
        // url: "/airwallex_order",

        data: {
            action: "payermax_order",
            appid,
            sandbox,
            price,      // 商品价格
            userId,     // 用户 ID
            itemSku,    // 商品 sku
            country,
            paymentDetail: {
                paymentMethodType: paymethod,
                targetOrg: org
            }
        }
    });
}

// 查询订单结果
export function checkOrderResultPayerMax(uid, orderId, sandbox, goodsList) {
    return request({
        // 测试用
        // baseURL: "http://10.80.1.176:8888",     // 测试用
        // url: "/check_airwallex_order",     // 测试用

        method: "POST",
        data: {
            action: "check_payermax_order",
            orderId,
            sandbox,
            // appid,
            userId: uid,
            goodsList
        }
    })
}