<template>
  <div id="topup_login">
    <card-comp>
      <div class="contentTitle">{{ $t('center.account') }}</div>
      <div class="login"  :style="{'display': userId?'none':'flex'}" >
        <div class="loginText">{{ $t('center.userId') }}</div>
        <div class="loginRight">
          <div class="loginInput" ref="loginIpt">
            <input id="input"
                   type="text"
                   name="loginInput"
                   v-model="iptUserId"
                   :placeholder="$t('center.enterId')"
                   class="textShow1"
                   @focus="changeIptBorder"
                   @blur="rmIptBorderStyle"
                   @keydown="showDelBtn"
                   ref="idIpt">
            <div class="deleteNum"
                 style="display: none;"
                 ref="delIptBtn"
                 @click="clearIpt">
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" class="deleteSvg"><path d="M9.99995 19.4962C12.4842 19.4962 14.7332 18.4892 16.3612 16.8612C17.9892 15.2333 18.9961 12.9842 18.9961 10.5C18.9961 8.01581 17.9892 5.76677 16.3612 4.13876C14.7332 2.51078 12.4842 1.50385 9.99995 1.50385C7.51575 1.50385 5.26671 2.51078 3.6387 4.13876C2.01072 5.76677 1.00378 8.01581 1.00378 10.5C1.00378 12.9842 2.01072 15.2333 3.6387 16.8612C5.26671 18.4892 7.51575 19.4962 9.99995 19.4962Z" fill="#D1D1D1"></path><path d="M6.24402 6.74408L13.7559 14.256" stroke="white" stroke-width="1.5"></path><path d="M6.24377 14.2559L13.7557 6.744" stroke="white" stroke-width="1.5"></path></svg>
            </div>
            <div class="questionMark"
              @click="showIptImage(true)"
                @mouseenter="showIptImage(true)"
                 @mouseleave="showIptImage(false)">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none" class="inputSvg"><path d="M12.5566 22.4409C15.318 22.4409 17.818 21.3216 19.6277 19.5119C21.4373 17.7023 22.5566 15.2023 22.5566 12.4409C22.5566 9.67946 21.4373 7.17946 19.6277 5.36979C17.818 3.56015 15.318 2.44086 12.5566 2.44086C9.79524 2.44086 7.29524 3.56015 5.48557 5.36979C3.67593 7.17946 2.55664 9.67946 2.55664 12.4409C2.55664 15.2023 3.67593 17.7023 5.48557 19.5119C7.29524 21.3216 9.79524 22.4409 12.5566 22.4409Z" stroke="#999999" stroke-width="1.5" stroke-linejoin="round"></path><path d="M12.5566 14.7532V12.7532C14.2135 12.7532 15.5566 11.4101 15.5566 9.75323C15.5566 8.09638 14.2135 6.75323 12.5566 6.75323C10.8998 6.75323 9.55664 8.09638 9.55664 9.75323" stroke="#999999" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M12.5566 19.2532C13.247 19.2532 13.8066 18.6936 13.8066 18.0032C13.8066 17.3129 13.247 16.7532 12.5566 16.7532C11.8663 16.7532 11.3066 17.3129 11.3066 18.0032C11.3066 18.6936 11.8663 19.2532 12.5566 19.2532Z" fill="#999999"></path></svg>
            </div>
          </div>
          <div class="loginBtn" @click="loginUser"> {{ $t('center.logIn') }} </div>
          <div class="inputImage" style="display: none; z-index: 2;" ref="iptImage">
            <img src="@/assets/imagine/topup/id.png" alt="question mark imgae" class="inputImg">
          </div>
        </div>
      </div>


      <div class="login" :style="{'display': userId?'flex':'none'}">
        <div class="loginText">{{ $t('center.userId') }}</div>
        <div class="loginRight">
          <span class="user_id">{{userId}}</span>
          <div class="loginBtn" @click="loginOut"> {{ $t('center.logOut') }} </div>
        </div>
      </div>
    </card-comp>
  </div>
</template>

<script>
import CardComp from "@/components/CardComp";
// import { userLogin } from "@/request/page";
import { testUserTestLogin, userLogin } from "@/request/page";

export default {
  name: "TopupLogin",
  data() {
    return {
      iptUserId: '',  // 在当前页面输入的用户 ID
      testBoolean: false,   // 测试用
      userDataObj: {},    // 用户数据(用户 ID 和 订单 ID)
      appid: 0,
    }
  },
  computed: {
    userId() {   // 用户的 ID 数据（用于用户登录）
      return this.$store.state?.userMsg?.userId;
    }
  },
  components: {
    CardComp,
  },
  mounted() {
    this.loginAuto();     // 自动登录
  },
  methods: {
    // 如果input 标签获取焦点
    changeIptBorder() {
      this.$refs.loginIpt.style.border = "2px solid #557be1";
    },

    // 如果输入框失去焦点，则去除 border 样式
    rmIptBorderStyle() {
      this.$refs.loginIpt.style.border = "1px solid #ddd";
    },

    // 监听 input 的输入事件
    showDelBtn(e) {
      if (e.code === "Enter") {
        this.loginUser();  // 用户登录
      }
    },

    // 用户登录
    loginUser() {
      if (!this.iptUserId) { return this.$store.commit("changeAlertMsg", "User ID cannot be empty"); }
      const [uid] = this.iptUserId.split(';');
      if (uid.length < 1) {
          return this.$store.commit("changeAlertMsg", "User ID is Invalid");
      }
      this.iptUserId = uid;
      this.$store.commit('isLoadCurrent', true);
      userLogin(uid, this.appid).then(res => {
        this.$store.commit('isLoadCurrent', false);
        const { status, appid } = res;   // 测试用

        if (!status) { return this.$store.commit("changeAlertMsg", `The current user does not exist.`) }
        this.$store.commit("changeLoginState", true);    // 切换当前状态为登录状态
        this.$store.commit("assignUserMsg", {"userId":this.iptUserId, appid});    // 清楚当前用户的信息
        this.appid = appid;
        console.log(appid);

        // 往 local storage 中存储数据
        const savaData = this.userDataObj;
        if (savaData.orderId) {
          savaData.userId = this.iptUserId;
          savaData.appid = appid;
          localStorage.setItem("ivyMergeNeverlandUserMsg", JSON.stringify(savaData));
        } else {
          localStorage.setItem("ivyMergeNeverlandUserMsg", JSON.stringify({userId: this.iptUserId, appid}))
        }
      }, error => {
        this.$store.commit("changeAlertMsg", {"alertContent": error});
        console.log(error);
      })
    },

    // 自动登录
    loginAuto() {
      const userDataStr = localStorage.getItem("ivyMergeNeverlandUserMsg");
      if (!userDataStr) { return; }    // 如果用户从未登录过，则不做任何处理
      const userDataObj = JSON.parse(userDataStr);    // 将其转化为 Obj 类型
      const { userId, appid } = userDataObj;     // 提取出用户 ID
      if (!userId) { return; }  // 如果不存在这条信息，则内容无效

      this.userDataObj = userDataObj;
      this.iptUserId = userId;    // 赋值用户 ID
      this.appid = appid;
      this.loginUser();   // 调用登录的函数
    },

    // 清楚 input 输入框中的所有文本内容
    clearIpt() {
      this.$refs.idIpt.value = "";
    },

    // 展示输入用户 ID 的示例
    showIptImage(isShow=true) {
      this.$refs.iptImage.style.display = isShow?"block":"none";
    },

    // 登出(退出登录)
    loginOut() {
      this.$refs.idIpt.value = '';    // 清空输入用户 ID 的值
      this.$store.commit("changeLoginState", false);    // 切换当前状态为 登出状态
      this.$store.commit("assignUserMsg", {});    // 清楚当前用户的信息
      localStorage.setItem("ivyMergeNeverlandUserMsg", JSON.stringify({}));    // 清空存储栈中的数据
    }
  },

  watch: {
    // 根据当前输入框的值，判断是否要显示清楚的图片
    iptUserId(iptCurrentVal) {
      this.$refs.delIptBtn.style.display = iptCurrentVal?"block":"none";    // 如果 input 输入框存在内容，就展示删除的内容框
    }
  }
}
</script>

<style scoped lang="scss">

.container {
      display: flex;
      align-items: center;
    }

    .item {
      background: lightgray;
      padding: 10px;
      margin: 5px;
      text-align: center;
    }

    .fixed-size {
      width: 100px; /* Fixed width */
    }

    .flexible {
      flex-grow: 1;
      flex-shrink: 1;
      max-width: 500px; /* Max width */
    }

    .inputImage img {
        /* height: 100%; */
        /* width: 100%; */
        position: absolute;
        box-sizing: border-box;
        // width: 800px;
        width: 100%;
        height: auto;
        border: 5px solid #557be1;
        box-shadow: 0 2px 7px 0 rgba(0,0,0,.15);
        // transform: translateX(-50%);
        left: 0;
        margin-top: 15px;
        border-radius: 15px;
      }

  #topup_login {
    .contentTitle {
      color: #333;
      font-size: large;
      font-weight: 600;
      line-height: 1.2;
      margin-bottom: 1.6rem;
    }
    .login {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      text-align: center;
      height: 62px;
      .loginText {
        font-size: large;
        color: #333;
        font-weight: 500;
        line-height: 1.8rem;
        margin-right: 11px;
        white-space: nowrap;
      }
      .loginRight {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        .loginInput {
          display: flex;
          flex-flow: row nowrap;
          border: 1px solid #ddd;
          padding: 9px 17px;
          border-radius: 9px;
          position: relative;
          align-items: center;
          flex-grow: 1;
          flex-shrink: 1;
          max-width: 400px;

          input {
            // width: 210px;
            border: none;
            outline: none;
            font-size: medium;
          }

          .questionMark {
            margin-top: 3px;
            // margin-left: 40px;
            svg {
              cursor: pointer;
            }
          }
          .deleteNum {
            position: absolute;
            height: 20px;
            width: 20px;
            top: 50%;
            transform: translateY(-50%);
            right: 57px;
            cursor: pointer;
          }
        }
        .loginBtn {
          margin-left: 12px;
          height: 50px;
          line-height: 50px;
          align-items: center;
          background-color: #557be1;
          color: #fff;
          font-weight: 600;
          cursor: pointer;
          text-align: center;
          padding: 0 32px;
          border-radius: 11px;
          white-space: nowrap;
        }
        .loginBtn:hover {
          background-color: #4869bf;
        }
      }
    }
  }
  span.user_id {
    text-align: center;
    vertical-align: middle;
    // display: inline-block;
    line-height: 50px;
    color: #333;
    font-size: large;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /* 适配移动端  */
  @media screen and (max-width: 800px) {
    #topup_login {
      .contentTitle {
        font-size: 16px;
      }
    }
    .loginText {
      font-size: 16px!important;
    }
    .loginRight {
      .loginInput {
        height: 38px;
        padding: 2px 7px!important;
        #input, input {
        }
        .questionMark {

        }
      }
      #input, input {
        height: 38px;
        width: 110px!important;
      }
      .loginBtn {
        height: 40px!important;
        line-height: 40px!important;
        padding: 0 12px!important;
        border-radius: 5px;
        font-size: small;
      }
      span.user_id {
        text-align: center;
        vertical-align: middle;
        display: inline-block;
        line-height: 25px;
        color: #333;
        font-size: small;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .deleteNum {
        right: 45px!important;
      }
      .inputImage img {
        // width: 218px !important;
        border: 3px solid #557be1 !important;
      }
    }

  }
</style>