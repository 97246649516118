<template>
  <div id="footer">
    <div class="bottom">
      <div class="bottomLeft">
        <a href="/" aria-label="go home" class="logoLink">
          <img src="@/assets/imagine/topup/icon.png" alt="logo image" width="166" height="50" class="logoImage">
        </a>
      </div>
      <div class="bottomRight">
        <div class="pageList">
<!--          <a href="#" aria-label="" class="pageTitle">User Agreement</a>-->
<!--          <a href="#" aria-label="" class="pageTitle">Privacy Policy</a>-->
<!--          <a href="#" aria-label="" class="pageTitle">GDPR</a>-->
          <span class="pageTitle" @click="changeRouter('/UserAgreement')">{{ $t('home.agreement') }}</span>
          <span class="pageTitle" @click="changeRouter('/PrivacyPolicy')">{{ $t('home.privacy') }}</span>
          <span class="pageTitle" @click="changeRouter('/gdpr')">GDPR</span>
          <a href="https://www.ivymobile.com/index.php?m=content&c=index&a=lists&catid=2" class="pageTitle">About Us</a>
        </div>
        <div class="copyright"> Copyright © 2021-2024 IvyMobile Games, All Rights Reserved. </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FootComp",
  methods: {
    changeRouter(path) {
      this.$router.push(path)
    }
  }
}
</script>

<style scoped lang="scss">
  #footer {
    margin-top: 30px;
    //margin-top: 2rem;
    background-color: #11224f;
    .bottom {
      max-width: 1100px;
      display: flex;
      align-items: center;
      padding: 50px 24px;
      margin: auto;
      .bottomLeft {
        .logoLink {
          display: flex;
          align-items: center;
          margin-right: 44px;
          .logoImage {
            height: 50px;
            width: auto;
          }
        }
      }
      .bottomRight {
        font-size: 14px;
        line-height: 20px;
        .pageList {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .pageTitle {
            color: #fff;
            font-weight: 500;
            margin-right: 38px;
            margin-bottom: 15px;
            font-size: 14px;
            cursor: pointer;
          }
        }
        .copyright {
          color: #a7adbd;
          font-weight: 400;
        }
      }
    }
  }

  /*  适配移动端 */
  @media screen and (max-width: 800px) {
    #footer {
      .bottom {
        display: block;
      }
    }
    .pageTitle {
      margin-right: 28px!important;
    }
    .pageList {
      margin-top: 35px;
    }
  }
</style>