<template>
  <div id="gdpr_view">
    <top-title/>
    <main-g-d-p-r/>
  </div>
</template>

<script>
import TopTitle from "@/views/gdpr/child/TopTitle";
import MainGDPR from "@/views/gdpr/child/MainGDPR";
export default {
  name: "GdprView",
  components: {
    TopTitle,
    MainGDPR
  }
}
</script>

<style scoped>

</style>