import { request } from "@/request/index";

// 获取订单链接
export function payPalOrder(goodsMsg, userId, goodsPackDetail, sandbox, appid, country) {
    return request({
        // baseURL: "http://10.80.1.176:8888",     // 测试用
        // url: "/paypal_order",                   // 测试用

        method: "POST",
        data: {
            action: "paypal_order",
            appid,
            sandbox,
            goodsMsg,
            userId,
            goodsPackDetail,
            country
        }
    })
}

// 查看订单的状态
export function checkPaypalOrderStatus(orderId, sandbox) {
    return request({
        // baseURL: "http://10.80.1.176:8888",     // 测试用
        // url: "/check_paypal_order",             // 测试用

        method: "POST",
        data: {
            action: "check_paypal_order",
            orderId,
            sandbox,
            // appid
        }
    })
}