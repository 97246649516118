<template>
  <div id="top_title">
    <div class="game_title" ref="topHeader">
      <span >Merge Neverland</span>
    </div>
    <h1>
      <span class="page_title" style="">Privacy Policy</span>
    </h1>
  </div>
</template>

<script>
export default {
  name: "TopTitle",
  mounted() {
    document.addEventListener("scroll", this.changeTopHeader)
  },
  methods: {
    changeTopHeader() {
      const scrollTopDistance = window.scrollY;
      const isShow = scrollTopDistance > 50;
      this.$refs.topHeader.style.backgroundColor = isShow?"#212121":"rgba(0, 0, 0, 0)";
    }
  },
  unmounted() {
    document.removeEventListener("scroll", this.changeTopHeader);
  },
}
</script>

<style scoped lang="scss">
  #top_title {
    height: 340px;
    background-image: url("@/assets/imagine/userAgreement/simple-header-blended-small.png");
    background-color: rgba(34, 110, 147, 1);
    background-size: cover;
    position: relative;
    color: #fff;
    .game_title {
      //background: rgba(125, 188, 255, .6);
      padding: 15px 30px;
      font-size: 20px;
      cursor: pointer;
    }
    //.game_scroll_title {
    .game_title {
      //height: 56px;
      padding: 15px 30px;
      font-size: 20px;
      cursor: pointer;
      background-color: #fff;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 20;
      //transition: opacity .5s, padding-left .125s;
      white-space: nowrap;
      background-color: rgba(0, 0, 0, 0);
    }
    h1 {
      display: flex;
      justify-content: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .page_title {
      font-family: Lato, sans-serif;
      font-weight: 300;
      word-wrap: break-word;
      box-sizing: border-box;
      background-color: rgba(0, 0, 0, 0);
      font-size: 80px;
      text-align: center;
    }
  }

  /* 兼容移动端 */
  @media screen and (max-width: 800px) {
    #top_title {
      height: 250px;
    }
    .game_title {
      font-size: 16px!important;
    }
    .page_title {
        font-size: 40px!important;
    }
  }
</style>