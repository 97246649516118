<template>
    <card-comp class="tabs">
      <!-- Tab 标题 -->
      <ul class="tab-titles">
        <li
          v-for="(tab, index) in tabs"
          :key="index"
          :class="{ active: selectedTab === index }"
          @click="selectedTab = index">
          {{ $t(tab.title) }}
        </li>
      </ul>
  
      <!-- Tab 内容 -->
      <div class="tab-content">
        <div
        v-for="(tab, index) in tabs"
        :key="index"
        v-show="selectedTab === index">
          <component :is="tab.component" :data="tab.data"></component>
        </div>
      </div>
    </card-comp>
  </template>
  
  <script>
  import CardComp from "@/components/CardComp";
  export default {
    data() {
      return {
        selectedTab: 0
      };
    },
    components: {
        CardComp
    },
    props: {
        tabs: {
            type: Array,
            required: true
        }
    }
  };
  </script>
  
  <style scoped>
  .tab-titles {
    display: flex;
    list-style: none;
    padding: 0;
    border-bottom: 1px solid #e4e4e4;
  }
  
  .tab-titles li {
    padding: 10px;
    cursor: pointer;
    margin-right: 10px;
    font-size: 18px;
    font-weight: 400;
  }
  
  .tab-titles li.active {
    border-bottom: 3px solid #557be1;
    font-weight: 700;
  }
  
  .tab-content {
    margin-top: 20px;
  }
  </style>
  