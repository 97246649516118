<template>
  <div id="main_gdpr">
    <p>Can we use your data to tailor ads for you? If you accept this consent policy, our partners will collect data and use MEID to personalize advertising and measure advertising effectiveness for you. Welcome to learn about how our partners collect and use your personal data when you agree to use our application.</p>
    <div id="content_t">
      <p>Our Partners’ Privacy Policy</p>
    </div>
    <div id="policy_list">
      <span class="policy_item"
            v-for="(srcItem, index) in srcList"
            :key="index">
        <span class="item_title">{{srcItem.title}}</span>
        <span> <a :href="srcItem.realHref">{{srcItem.showHref}}</a> </span>
      </span>
    </div>
    <div id="alert_bottom">
      <p>You can change your choice at any time in our Setting center.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainGDPR",
  data() {
    return {
      srcList: [
        {
          title: "Google",
          showHref: "https://policies.google.com/technologies/partner-sites",
          realHref: "https://policies.google.com/technologies/partner-sites"
        },
        {
          title: "Facebook",
          showHref: "https://m.facebook.com/about/privacy",
          realHref: "https://www.google.com/url?q=https%3A%2F%2Fm.facebook.com%2Fabout%2Fprivacy&sa=D&sntz=1&usg=AOvVaw3WNgqoU5Uggt1JicjxNsbz"
        },
        {
          title: "Adcolony",
          showHref: "https://www.adcolony.com/privacy-policy/",
          realHref: "https://www.google.com/url?q=https%3A%2F%2Fwww.adcolony.com%2Fprivacy-policy%2F&sa=D&sntz=1&usg=AOvVaw3C14rVUYyDYIvtmkLA7L-s"
        },
        {
          title: "Unity",
          showHref: "https://unity3d.com/legal/privacy-policy",
          realHref: "https://www.google.com/url?q=https%3A%2F%2Funity3d.com%2Flegal%2Fprivacy-policy&sa=D&sntz=1&usg=AOvVaw0njRJiS6j2pKe5H-tQ5Z5n"
        },
        {
          title: "Ironsource",
          showHref: "https://developers.ironsrc.com/ironsource-mobile/air/ironsource-mobile-privacy-policy/",
          realHref: "https://www.google.com/url?q=https%3A%2F%2Fdevelopers.ironsrc.com%2Fironsource-mobile%2Fair%2Fironsource-mobile-privacy-policy%2F&sa=D&sntz=1&usg=AOvVaw1X1DrIlOFCQXDqqzehIt1S"
        },
        {
          title: "Vungle",
          showHref: "https://vungle.com/privacy/",
          realHref: "https://www.google.com/url?q=https%3A%2F%2Fvungle.com%2Fprivacy%2F&sa=D&sntz=1&usg=AOvVaw3YsW4n4gGqOsgGAE9bx8-H"
        },
        {
          title: "Yandex",
          showHref: "https://yandex.ru/legal/confidential/index.html",
          realHref: "https://yandex.ru/legal/confidential/index.html"
        },
        {
          title: "Digital Turbine",
          showHref: "https://www.digitalturbine.com/privacy-policy/",
          realHref: "https://www.digitalturbine.com/privacy-policy/"
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
  div#main_gdpr {
    padding: 15px 48px 30px;
    color: rgba(33, 33, 33, 1);
    font-family: Lato, sans-serif;
    #content_t {
      p {
        margin-top: 15px;
        font-weight: 600;
      }
    }
  }
  p {
    line-height: 1.38;
    color: rgba(33, 33, 33, 1);
    font-family: Lato, sans-serif;
    font-size: 11pt;
    font-weight: 400;
  }

  #policy_list {
    display: flex;
    flex-flow: column nowrap;
    .policy_item {
      display: flex;
      flex-flow: column nowrap;
      a {
        //color: rgba(0, 101, 128, 1);
        //color: #006586;
        color: #277d93;
        text-decoration: underline;
      }
    }
  }
  #alert_bottom {
    margin-top: 15px;
  }

  /* 适配移动端 */
  @media screen and (max-width: 800px){
    #policy_list .policy_item a {
      color: #277d93;
      text-decoration: underline;
      font-size: 14px;
    }
    div#main_gdpr {
      padding: 15px 18px 30px;
    }
    #top_title {
      height: 350px;
    }
  }

</style>