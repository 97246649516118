import { request } from '@/request/index';

// 测试登录
export function testUserTestLogin(userId, appid) {
    return request({
        method: "POST",
        data: {
            action: "userLogin",
            userId,
            appid
        }
    })
}

// 用户登录
export function userLogin(userId, appid) {
    return request({
        baseURL: "https://webpay.iibingo.com",
        method: "GET",
        url: "/api/user/check",
        params: {
            // appid: appid,
            uid: userId
        }
    })
}